import React from 'react';
import createIconComponent from './createIconComponent';

const IconArrowLGDown = createIconComponent({
	content: <path d="M12,13.8l4.9-4.9l0.7,0.7l-4.9,4.9L12,15.2L6.4,9.5l0.7-0.7L12,13.8z"/>,
	height: 24,
	width: 24
});

IconArrowLGDown.displayName = 'IconArrowLGDown';

export default IconArrowLGDown;
