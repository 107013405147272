import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Calendar from './HowToWatchCalendar';
import Region from './HowToWatchRegion';
import Filter from './HowToWatchFilter';
import Events from './HowToWatchEvents';
import './HowToWatchContainer.scss';

const reduceArray = (array, testFn, propToAppend) => array.reduce((accumulator, item) => {
	if (testFn(item, accumulator)) return [ ...accumulator, propToAppend ? item[propToAppend] : item ];
	return accumulator;
}, []);

const HowToWatchContainer = ({ data }) => {
	const events = get(data, 'events', []);
	const defaultCountry = data.countryCode || get(data, 'defaultCountry.iso', '');
	const countries = get(data, 'countries', []);

	const [selectedRegion, setSelectedRegion] = useState(defaultCountry);
	const [regionsOpen, setRegionsOpen] = useState(false);
	const [activeFilter, setActiveFilter] = useState(data.showAllLabel);
	const [activeEventIndex, setActiveIndex] = useState(0);
	const [accordionOpen, setAccordionOpen] = useState(false);
	const activeEvent = events[activeEventIndex];

	const setActiveIndexIfChanged = index => {
		if (index !== activeEventIndex) setActiveIndex(index);
	};

	// Use for restructuring data into groups based on a property
	const sortIntoGroups = (rawChannels, sortingProp, nextSortFn = (args) => args, storeFirstOfProp) => {
		const sortingOptions = rawChannels.reduce((accumulator, channel) => {
			const sortName = get(channel, sortingProp);
			if (accumulator.indexOf(sortName) > -1) return accumulator;
			return [ ...accumulator, sortName ];
		}, []);

		const structuredChannels = sortingOptions.map(option => {
			const relatedChannels = reduceArray(rawChannels, chan => get(chan, sortingProp) === option);

			const returnValue = {
				name: option,
				list: nextSortFn(relatedChannels),
			};

			if (storeFirstOfProp) {
				const firstItem = relatedChannels[0];
				if (firstItem) returnValue[storeFirstOfProp] = firstItem[storeFirstOfProp];
			}

			return returnValue;
		});

		return structuredChannels;
	};

	// Retrieve list of filters from events
	const filters = reduceArray(activeEvent.channels, (item, accumulator) => {
		const matchesActiveCountry = get(item, 'broadcaster.countries', []).find(c => c.iso === selectedRegion);
		const isNotDuplicate = accumulator.indexOf(item.category) === -1;
		return matchesActiveCountry && isNotDuplicate;
	}, 'category').sort((a, b) => a.localeCompare(b));


	// Filter events using selected filter
	const channels = (() => {
		let filteredChannels = reduceArray(activeEvent.channels, chan => {
			const countriesForChannel = get(chan, 'broadcaster.countries', []);
			return countriesForChannel.find(country => country.iso === selectedRegion);
		});

		if (activeFilter !== data.showAllLabel) {
			filteredChannels = reduceArray(filteredChannels, chan => chan.category === activeFilter);
		}

		return sortIntoGroups(filteredChannels, 'broadcaster.channel', chans => sortIntoGroups(chans, 'category'), ['broadcaster']);
	})();

	// Get name of region from selected ISO
	const selectedRegionName = (() => {
		const regionWithISO = countries.find(c => c.iso === selectedRegion);
		if (regionWithISO) return regionWithISO.name;
		return '';
	})();

	return (
		<section className="c-how-to-watch">
			<Calendar
				heading={data.carouselHeading}
				slides={events}
				onChangeSlide={setActiveIndexIfChanged} />
			<Region
				howToWatchLabel={data.locationLabel}
				selectedRegion={selectedRegionName}
				changeLabel={regionsOpen ? data.cancelButtonLabel : data.changeButtonLabel}
				onClickChange={() => setRegionsOpen(!regionsOpen)}
				regionOptions={countries}
				regionsOpen={regionsOpen}
				onChangeRegion={e => {
					setSelectedRegion(e.value);
					setRegionsOpen(false);
				}} />
			<Filter
				filters={[ data.showAllLabel, ...filters ]}
				activeFilter={activeFilter}
				onClickFilter={ e=> {
						setActiveFilter(e);
						setAccordionOpen(false);
					}
				}
				open={accordionOpen}
				onToggle={() => setAccordionOpen(!accordionOpen)} />
			<Events sections={channels} noCoverageOptionsLabel={data.noCoverageOptionsLabel} />
		</section>
	);
};

HowToWatchContainer.propTypes = {
	data: PropTypes.object,
};

HowToWatchContainer.defaultProps = {
	data: undefined,
};

export default HowToWatchContainer;
