import React from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { Grid, Row, Cell } from '../../Grid';
import './OnwardJourneyBlock.scss';
import OnwardJourneyBlockItem from './OnwardJourneyBlockItem';

const OnwardJourneyBlock = ({ data }) => (
	data && data.pages && data.pages.length > 0 ?
		<div className="c-onward-journey-block">
			<Grid noVerticalPadding>
				<Row>
					<Cell smCols={2} mdCols={3} lgCols={1} xlCols={1} className="c-onward-journey-block__spacer" />
					{
						data.pages.map(child =>
							<Cell smCols={2} mdCols={3} lgCols={5} xlCols={5} key={nanoid()} className="c-onward-journey-block__item">
								<OnwardJourneyBlockItem {...child} />
							</Cell>
						)
					}
				</Row>
			</Grid>
		</div>
		: null
);

OnwardJourneyBlock.propTypes = {
	data: PropTypes.shape({
		pages: PropTypes.array,
	}).isRequired,
};

OnwardJourneyBlock.defaultProps = {
	data: undefined,
};

export default OnwardJourneyBlock;
