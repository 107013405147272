import React from 'react';
import { Select } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import "./Filter.scss";
import PropTypes from 'prop-types';

export default function Filter(props) {
    const { Option } = Select;
	const {photosignupDropdownData, handleChange, photosignupData} = props

	photosignupData.map((val) => {
      delete val.createdAt;
      delete val.createdBy;
      delete val.createdOn;
      delete val.updatedAt;      ;
      delete val.__v;
    })

  return (
    <div className='sortBy-wrapper'>
      <div>
      <label className='fitler-label'>Filter By : </label>
        <Select
              allowClear
              defaultValue="Select Option"
              id="sortOptions"
              onChange={handleChange}
            >
              {Object.keys(photosignupDropdownData).length
                ? photosignupDropdownData.map((element) => {
                    return (
                      <Option key={element} value={element}>
                        {element}
                      </Option>
                    );
                  })
                : null}
            </Select>
            </div>
            {photosignupData && photosignupData.length>0 && <CSVLink filename={"Photoboat-Request"} className='photoboat-download-link' data={photosignupData.length > 0 && photosignupData}>
                <DownloadOutlined />Download
              </CSVLink> }
    </div>
  )
}

Filter.propTypes = {
	photosignupDropdownData: PropTypes.array,
	handleChange: PropTypes.func,
	photosignupData: PropTypes.array
}
