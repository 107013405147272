import React from 'react';
import {getReplaceMap} from './replace-map';

const InFormParagraph = ({fieldData, isAppView}) => {
	const field = fieldData;

	const contentToReplace = getReplaceMap();
	if (field.miniContent) {
		Object.keys(contentToReplace).forEach((key) => {
			if (field.miniContent.includes(key)) {
				field.miniContent = field.miniContent.replace(key, contentToReplace[key]);
			}
		});
	}

	return (
		<>
			{
				!isAppView && field.appWebViewOnly ? null :
					<div className={`flex flex-col in-form-row ${(field.extraClassNames
					&& field.extraClassNames.length
					&& field.extraClassNames.join(' ')) || ''}`}
						 dangerouslySetInnerHTML={{ __html: field.miniContent }} />
			}
		</>
	);

};

export default InFormParagraph;
