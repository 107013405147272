import {getSsoAPIURL} from '../../lib/content-api-utils';
import {fetchGetUserData} from '../../utils/fetchApi';

function getCountry(countryCode, contentfulContent, index) {

	const country = contentfulContent.formFields[index].options && contentfulContent.formFields[index].options.filter((obj, i) => {

		const findCountry = obj.search('(' + countryCode + ')');
		if (findCountry != -1) {
			return obj;
		}
	})
	return country;
}

export async function MyAccountFormInitialization(setValue, contentfulData) {
	try {
		const userUrl = await getSsoAPIURL('user/get-preferences');
		const userDataJson = await fetchGetUserData(userUrl);
		const userDataRes = await userDataJson.json()
		if (userDataRes.status === 'success' && contentfulData.formFields) {
			contentfulData.formFields.forEach(({title}, index) => {
				if (title === 'firstName')
					setValue(title, userDataRes.response.firstName);
				else if (title === 'lastName')
					setValue(title, userDataRes.response.lastName);
				else if (title === 'company') {
					const companyName = userDataRes.response.organization;
					setValue(title, companyName);
				} else if (title === 'phoneNumber') {
					const PhoneNumber = userDataRes.response.mobile;
					setValue(title, PhoneNumber);
				} else if (title === 'jobTitle') {
					setValue(title, userDataRes.response.title);
				} else if (title === 'country') {
					const countryName = getCountry(userDataRes.response.country, contentfulData, index)
					setValue(title, countryName);
				}
			})
		}
	} catch (error) {
		console.log(error);
		return error;
	}

	return {}
}


