import {fetchGetData, fetchGetUserData, fetchPostData} from '../../../utils/fetchApi';
import {toast} from 'react-toastify';
import axios from 'axios';
import {getSsoAPIURL} from '../../../lib/content-api-utils';
import {fetchPageData} from '../../../utils/fetchApiData';

const fetchUserDetailsAPI = async (token) => {
	const userUrl = await getSsoAPIURL('user/get-preferences');
	const allUserData = fetchGetUserData(userUrl);
	return allUserData
		.then((response) => response.json())
		.then(async (data) => {
			if (data.status === "success") {
				sessionStorage.setItem('displayname', data.response.firstName + " " + data.response.lastName);
				sessionStorage.setItem('userName', data.response.emailAddress);
				sessionStorage.setItem('userRole', data.response.User_Role);


				const userUpdateDbUrl = await fetchPageData('user/updateDb');
				const updateRes = await fetchPostData(userUpdateDbUrl, 'POST', data.response, true);

				// sessionStorage.setItem('activeLanguage', activeLanguage);
			}
			return data
		})
		.catch((error) => {
			console.log(error)
		});
}


// user login api
const userLoggedInApi = async (userObj) => {
	const loginPayload = {
		username: userObj.emailAddress,
		password: userObj.password,
	};
	const loginUrl = await fetchPageData('user/login');


	const loginResponseData = fetchPostData(loginUrl, 'POST', loginPayload, false);
	return loginResponseData
		.then((res) => res.json())
		.then((resObj) => {
			if (resObj.status === 1) {
				//if (resObj.data.access_token.length > 0) {
				// fetchUserDetailsAPI(resObj.data.authnToken)
				sessionStorage.setItem('token', true);
				ssoURLApiCall()
				//}
			} else if (resObj.status === 0 && resObj.data === 'User not yet approved by Admin') {
				toast.error(resObj.data, {
					position: toast.POSITION.TOP_CENTER
				});

			} else {
				toast.error('Invalid email or password', {
					position: toast.POSITION.TOP_CENTER
				});
			}
			return resObj
		})
		.catch((error) => {
			console.log(error)
		});
}

const ssoURLApiCall = () => {
	const getSSOUrl = fetchPageData(`user/env_variables`);
	getSSOUrl.then((res) => {
		const apiURL = res;
		const ssoURLResponseData = fetchGetData(apiURL, 'GET', true);
		return ssoURLResponseData
			.then((res) => res.json())
			.then((resObj) => {
				if (resObj.status === 1) {
					return axios({
						method: "post",
						url: `${resObj.data.ORACLEBASEURL}sso/v1/sdk/session`,
						data: {"access_token": typeof window !== 'undefined'},
						headers: {"Content-Type": 'application/x-www-form-urlencoded'},
						credentials: 'include',
					})
				}
			})
			.catch((error) => {
				console.log(error)
			});
	})
}

// Reset Password api call
const resetPasswordApiCall = async (emailId, reset, url) => {
	//const getUserIdUrl = await getSsoAPIURL(`user/resetmail/${emailId}`);
	const getUserIdUrl = await getSsoAPIURL(`user/send-forgot-password-notification`);
	const redirectURL = location.origin + url;


	let resetPasswordParam = {
		userName : emailId,
        operation: "FORGOT_PASSWORD",
		emailTemplate : "MHPasswordReset",
		redirectURL : redirectURL,
		firstName: emailId,
		recipient: emailId
	}
	
	
	const userIdResponseData = fetchPostData(getUserIdUrl, 'POST', resetPasswordParam, false);
	return userIdResponseData
		.then((res) => res.json())
		.then(async (resObj) => {
			if (resObj.status === 1) {
				await resObj
				reset();
			}
			return resObj
		})
		.catch((error) => {
			console.log(error)
			return error
		});
}

const uploadUserProfilePicture = async () => {
	const getProfileImageURL = await fetchPageData(`user/profileImage`);
	const profilePicture = await fetchGetData(getProfileImageURL, "GET", true);
	let profilePictureData = await profilePicture.json();
	return profilePictureData;
};

export {
	userLoggedInApi,
	fetchUserDetailsAPI,
	resetPasswordApiCall,
	uploadUserProfilePicture
};
