import { format } from 'date-fns';
import {fetchPostData} from '../../../utils/fetchApi';
import {getSsoAPIURL} from '../../../lib/content-api-utils';

export const registerUsers = async (values, captchaValue, redirectURL) => {
	if (values && Object.keys(values).length > 0 && captchaValue.length > 0) {
		const countryCode = values.country.slice(-4).replace('(', '').replace(')', '');
		const registrationFormPayLoadData = {
			emailAddress: values.emailAddress,
			password: values.password,
			firstName: values.firstName,
			lastName: values.lastName,
			country: countryCode,
			dateOfBirth: format(new Date(values.dateOfBirth),'yyyy-MM-dd') ,
			applicationName : process.env.APP_NAME_MEDIAHUB,
			tAndCAccepted: values.tAndCAccepted,
			gdprConsent: values.gdprConsent,
			mobile: values.phoneNumber,
			organization: values.company,
			title: values.jobTitle,
			mhAccess: 'Pending',
			thirdPartyConsent: false,
		};

		const getRegistrationUrl = await getSsoAPIURL('user/register');

		if (Object.keys(registrationFormPayLoadData).length > 0) {
			const registrationResponseData = fetchPostData(getRegistrationUrl, 'POST', registrationFormPayLoadData, false);

			return registrationResponseData
				.then(response => response.json())
				.then(async data => {
					if(data.status === 'success') {
						const userVerificationData = {
							firstName: registrationFormPayLoadData.firstName,
							redirectURL: location.origin + redirectURL,
							emailTemplate: "MHUserVerification",
							recipient: registrationFormPayLoadData.emailAddress,
							tokenUrl: "user/generate-token",
							userName: registrationFormPayLoadData.emailAddress,
							operation: "VERIFY_USER"
						};

						const sendEmailURL = await getSsoAPIURL('user/send-verify-email-notification');
						await fetchPostData(sendEmailURL, 'POST', userVerificationData, false);
					}
					return data
				})
				.catch(error => {
					console.warn(error)
					return error
				});
		}
		return {};
	}
};
