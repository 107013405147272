import React from 'react';
import createIconComponent from './createIconComponent';

const IconCheckLG = createIconComponent({
	content: <path d="M10.2,17.3L9.5,18l-6.4-6.4l0.7-0.7l5.7,5.7L20.1,6l0.7,0.7L10.2,17.3z"/>,
	height: 24,
	width: 24
});

IconCheckLG.displayName = 'IconCheckLG';

export default IconCheckLG;
