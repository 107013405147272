import { useState, useEffect } from 'react';

function getWindowDimensions() {
	if(typeof window !== 'undefined') {
		const {innerWidth} = window;
		return {
			isSm: innerWidth < 768,
			isMd: innerWidth < 1024 && innerWidth >= 768,
			isLg: innerWidth < 1440 && innerWidth >= 1024,
			isXL: innerWidth >= 1440,
			screenWidth: innerWidth
		};
	}
	return {}
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}
