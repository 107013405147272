/**
 * Retrieve the data for a page asynchronously via the Fetch API.
 * API URL for the page.
 * A promise to return the page data as given in the response body.
 */
export async function fetchPageData(pageUrl) {
	const fullUrl =  process.env.REACT_APP_API_URL_SERVER + pageUrl;
	return fullUrl;
}

export const API_TOKEN = typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem("token")
