
import moment from 'moment';
import { toast } from 'react-toastify';
import { fetchPostEditEvent, fetchGetData } from '../../../../utils/fetchApi';
import { fetchPageData } from '../../../../utils/fetchApiData';

const getEventsData = () => {
	let getInitialEventData = [];
	const getAllEventAPIURL = fetchPageData(`event`);
	return getAllEventAPIURL.then(apiURL => {
		const allEventData = fetchGetData(apiURL, 'GET', true);
		return allEventData
			.then(response => response.json())
			.then(resObj => {
				if (resObj.status === 1) {
					getInitialEventData = resObj && resObj.data;
				}
				else if(resObj.status === 0){
					return {
						status: "logout"
					}
				}
				return getInitialEventData;
			})
			.catch((error) => {
				console.log(error)
			});
	})
}


// convert Data to formData object
function getFormData(object) {
	const formData = new FormData();
	Object.keys(object).forEach(key => {
		if (typeof object[key] !== 'object' && key !== 'photoUrl') {
			formData.append(key, object[key])
		} else if(key === 'photoUrl') {
			formData.append(key, object[key])
		}
		else formData.append(key, JSON.stringify(object[key]))
	})
	return formData;
}

export const PressConferenceCreateNewEventsForm = async (values, reset, contentfulData) => {
	const submitFormDataObj = {
		eventCreator: values.eventCreator,
		eventTitle: values.eventTitle,
		participants: values.participants,
		eventDate: values.eventDate,
		eventTime: values.eventTime,
		eventTimeZone: values.eventTimeZone,
		eventEndDate: values.eventEndDate,
		eventEndTime: values.eventEndTime,
		eventEndTimeZone: values.eventEndTimeZone,
		liveRace1Url: values.liveRace1Url,
		liveRace1StartTimeZone:values.liveRace1StartTimeZone,
		liveRace1StartDate: values.liveRace1StartDate,
		liveRace1StartTime:values.liveRace1StartTime,
		liveRace1EndTimeZone:values.liveRace1EndTimeZone,
		liveRace1EndDate:values.liveRace1EndDate,
		liveRace1EndTime: values.liveRace1EndTime,
		liveRace2Url: values.liveRace2Url,
		liveRace2StartTimeZone:values.liveRace2StartTimeZone,
		liveRace2StartDate: values.liveRace2StartDate,
		liveRace2StartTime:values.liveRace2StartTime,
		liveRace2EndTimeZone:values.liveRace2EndTimeZone,
		liveRace2EndDate:values.liveRace2EndDate,
		liveRace2EndTime: values.liveRace2EndTime,
		submittingQuestions: "Always on",
		urltype: values.urltype,
		mediaUrl: values.urltype === 'Audio/Video URL' ? values.audioVideoURL : '',
		zoomMeetingId: values.urltype === 'Zoom Meeting' ? values.zoomMeetingId : '',
		zoomPassword: values.urltype === 'Zoom Meeting' ? values.zoomMeetingPassword : '',
		photoUrl: values.photoUrl[0],
		eventDescription: values.eventDescription,
		liveRaceTitle: values.liveRaceTitle,
		liveRaceDescription: values.liveRaceDescription,
		raceReplay1Url: values.raceReplay1Url,
		raceReplay2Url: values.raceReplay2Url,
		questionsBeforePC: values.questionsBeforePC
	}
	const payLoadBody = await getFormData(submitFormDataObj);
	const getAddEventAPIURL = await fetchPageData(contentfulData.successUrl);
	const addEventData = await fetchPostEditEvent(getAddEventAPIURL, 'POST', payLoadBody, true);
	if (addEventData.status === 200) {
		reset();
	} else {
		toast.error(contentfulData.errorMessage, { position: toast.POSITION.TOP_CENTER });
	}
	return {}
}

export const PressConferenceEditEventsForm = async (values, contentfulData, eventId, getValues) => {

	const submitFormDataObj = {
		eventCreator: values.eventCreator,
		eventTitle: values.eventTitle,
		participants: typeof values.participants === 'string' ? values.participants : values.participants[0],
		eventDate: values.eventDate,
		eventTime: values.eventTime,
		eventTimeZone: values.eventTimeZone,
		eventEndDate: values.eventEndDate,
		eventEndTime: values.eventEndTime,
		eventEndTimeZone: values.eventEndTimeZone,
		liveRace1Url: values.liveRace1Url,
		liveRace1StartTimeZone:values.liveRace1StartTimeZone,
		liveRace1StartDate: values.liveRace1StartDate,
		liveRace1StartTime:values.liveRace1StartTime,
		liveRace1EndTimeZone:values.liveRace1EndTimeZone,
		liveRace1EndDate:values.liveRace1EndDate,
		liveRace1EndTime: values.liveRace1EndTime,
		liveRace2Url: values.liveRace2Url,
		liveRace2StartTimeZone:values.liveRace2StartTimeZone,
		liveRace2StartDate: values.liveRace2StartDate,
		liveRace2StartTime:values.liveRace2StartTime,
		liveRace2EndTimeZone:values.liveRace2EndTimeZone,
		liveRace2EndDate:values.liveRace2EndDate,
		liveRace2EndTime: values.liveRace2EndTime,
		submittingQuestions: "Always on",
		urltype: values.urltype,
		mediaUrl: values.urltype === 'Audio/Video URL' ? values.audioVideoURL : '',
		zoomMeetingId: values.urltype === 'Zoom Meeting' ? values.zoomMeetingId : '',
		zoomPassword: values.urltype === 'Zoom Meeting' ? values.zoomMeetingPassword : '',
		photoUrl: typeof values.photoUrl === 'string' ? getValues('photoUrl') : values.photoUrl[0]  ,
		eventDescription: values.eventDescription,
		liveRaceTitle: values.liveRaceTitle,
		liveRaceDescription: values.liveRaceDescription,
		raceReplay1Url: values.raceReplay1Url,
		raceReplay2Url: values.raceReplay2Url,
		questionsBeforePC: values.questionsBeforePC
	}

	const payLoadBody = await getFormData(submitFormDataObj);
	const editEventAPIURL = await fetchPageData(`event/${eventId}`);
	const editEventData = await fetchPostEditEvent(editEventAPIURL, 'PATCH', payLoadBody, true);
	if (editEventData.status === 200) {
		await toast.success(contentfulData.successMessage, {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 1000,
			hideProgressBar: true
		});
	} else {
		toast.error(contentfulData.errorMessage, { position: toast.POSITION.TOP_CENTER });
	}
	return editEventData;
}
export const PressConferenceEditEventsPreFilledForm = (setValue, preFillValues, radioUpdateFlag, setRadioUpdateFlag) => {
	setValue('eventCreator', preFillValues.eventCreator)
	setValue('eventTitle', preFillValues.eventTitle)
	setValue('eventDescription', preFillValues.eventDescription)
	setValue('participants', preFillValues.participants)
	setValue('eventDate', moment(preFillValues.eventDate).format('YYYY-MM-DD').toString())
	setValue('eventTime', preFillValues.eventTime)
	setValue('eventTimeZone', preFillValues.eventTimeZone)
	setValue('eventEndDate', moment(preFillValues.eventEndDate).format('YYYY-MM-DD').toString())
	setValue('eventEndTime', preFillValues.eventEndTime)
	setValue('eventEndTimeZone', preFillValues.eventEndTimeZone)
	setValue('urltype', preFillValues.urltype)
	setValue('liveRace1Url', preFillValues.liveRace1Url)
	setValue('liveRace1StartTimeZone', preFillValues.liveRace1StartTimeZone)
	setValue('liveRace1StartDate', moment(preFillValues.liveRace1StartDate).format('YYYY-MM-DD').toString())
	setValue('liveRace1StartTime', preFillValues.liveRace1StartTime)
	setValue('liveRace1EndTimeZone', preFillValues.liveRace1EndTimeZone)
	setValue('liveRace1EndDate', moment(preFillValues.liveRace1EndDate).format('YYYY-MM-DD').toString())
	setValue('liveRace1EndTime', preFillValues.liveRace1EndTime)
	setValue('liveRace2Url', preFillValues.liveRace2Url)
	setValue('liveRace2StartTimeZone', preFillValues.liveRace2StartTimeZone)
	setValue('liveRace2StartDate', moment(preFillValues.liveRace2StartDate).format('YYYY-MM-DD').toString())
	setValue('liveRace2StartTime', preFillValues.liveRace2StartTime)
	setValue('liveRace2EndTimeZone', preFillValues.liveRace2EndTimeZone)
	setValue('liveRace2EndDate', moment(preFillValues.liveRace2EndDate).format('YYYY-MM-DD').toString())
	setValue('liveRace2EndTime', preFillValues.liveRace2EndTime)
	setValue('audioVideoURL', preFillValues.mediaUrl)
	setValue('photoUrl', preFillValues.photoUrl)
	setValue('zoomMeetingId', preFillValues.zoomMeetingId)
	setValue('zoomMeetingPassword', preFillValues.zoomPassword)
	setValue('liveRaceTitle', preFillValues.liveRaceTitle)
	setValue('liveRaceDescription', preFillValues.liveRaceDescription)
	setValue('raceReplay1Url', preFillValues.raceReplay1Url)
	setValue('raceReplay2Url', preFillValues.raceReplay2Url)
	setValue('questionsBeforePC', preFillValues.questionsBeforePC)
	setRadioUpdateFlag(!radioUpdateFlag)

	return preFillValues
}

export const deleteEventsForm = async (eventId) => {
	const deleteEventAPIURL = await fetchPageData(`event/${eventId}`);
	const deleteEventData = await fetchGetData(deleteEventAPIURL,'DELETE',true);
	if (deleteEventData.status === 200) {
		await toast.success('Event Deleted Successfully', {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 1000,
			hideProgressBar: true
		});
	} else {
		toast.error('Unable to delete event', { position: toast.POSITION.TOP_CENTER });
	}
}

export { getEventsData, getFormData }
