import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconPhotoshelter = createIconComponent({
	content: (
		<Fragment>
			<path fill="#00A0F0" d="M45.5716.27 45.57,40.62 80.95,50.1 80.95,9.48z"/>
			<path fill="#D9D9D9" d="M80.95,9.48 35.38,18.22 0,8.75 45.57,0z"/>
			<path fill="#40B8F4" d="M45.57,40.62 35.38,42.58 35.38,58.85 35.38,58.85 80.95,50.1z"/>
			<path fill="#FFFFFF" d="M0,8.75 35.38,18.22 35.38,72 0,49.37z"/>
		</Fragment>
	),
	height: 40,
	width: 80

});

IconPhotoshelter.displayName = 'IconPhotoshelter';

export default IconPhotoshelter;
