import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import liveFeedTweetUrl from '../../../lib/livefeed-tweet-url';
import markdown from '../../../lib/markdown-parser';
import { IconFacebook, IconTwitter } from './../../Icons';

const LiveFeedItem = ({
	updated,
	title,
	content,
	embedId,
	embedType,
	convertTimeStampToElapsedTime,
	convertTimeStampToTime,
	handleEmbed,
	websiteUrl,
}) => (
	<Fragment>
		<div className="c-live-feed__item__time-stamp">
			<span className="c-live-feed__item__time-stamp__elapsed-time">
				{convertTimeStampToElapsedTime(updated)}
			</span>
			<span className="c-live-feed__item__time-stamp__time">
				{convertTimeStampToTime(updated)}
			</span>
		</div>
		<div className="c-live-feed__item__content">
			<h4 className="c-live-feed__item__heading">{title}</h4>
			{
				content && <span dangerouslySetInnerHTML={{ __html: markdown(content || '') }}></span>
			}
			{
				embedId && embedType ?
					<div className="c-live-feed__embed">
						{handleEmbed(embedId, embedType)}
					</div>
					: null
			}
			<div className="c-live-feed__item__social">
				{/* TODO: finish social sharing */}
				<Link
					to={`https://www.facebook.com/sharer/sharer.php?u=${websiteUrl}`}
					target="_blank"
					rel="noopener noreferrer"
					//onClick={() => trackAnalytics('offsite-links', { action: 'Facebook', label: socialLinks.facebook })}
				>
					<IconFacebook />
				</Link>

				<Link
					to={liveFeedTweetUrl(title, websiteUrl, embedType, embedId)}
					target="_blank"
					//onClick={() => trackAnalytics('offsite-links', { action: 'Twitter', label: socialLinks.twitter })}
				>
					<IconTwitter />
				</Link>
			</div>
		</div>
	</Fragment>
);

LiveFeedItem.propTypes = {
	updated: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string,
	embedId: PropTypes.string,
	embedType: PropTypes.string,
	convertTimeStampToElapsedTime: PropTypes.func.isRequired,
	convertTimeStampToTime: PropTypes.func.isRequired,
	handleEmbed: PropTypes.func.isRequired,
	websiteUrl: PropTypes.string,
};

LiveFeedItem.defaultProps = {
	updated: '',
	title: '',
	content: '',
	embedId: '',
	embedType: '',
	websiteUrl: '',
};

export default LiveFeedItem;
