import React from 'react';
import createIconComponent from './createIconComponent';

const IconLinkedIn = createIconComponent({
	content: (
		<path
		d="M 5.6190272,23.531468 H 1.21653 V 7.3889788 H 5.6190272 Z M 3.4177786,5.52819 c -1.4176041,0 -2.56812338,-1.1593243 -2.56812338,-2.5886684 0,-1.4293441 1.15051928,-2.58866844 2.56812338,-2.58866844 1.4176041,0 2.5681234,1.15932434 2.5681234,2.58866844 0,1.4293441 -1.1490517,2.5886684 -2.5681234,2.5886684 z M 23.229016,23.531468 h -4.402497 v -8.223864 c 0,-4.942537 -5.869996,-4.568325 -5.869996,0 v 8.223864 H 8.5540254 V 7.3889788 H 12.956523 V 9.9791146 C 15.005152,6.1841621 23.229016,5.9038697 23.229016,13.612643 Z"
		/>
	),
	height: 24,
	width: 24
});

IconLinkedIn.displayName = 'IconLinkedIn';

export default IconLinkedIn;
