import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button} from '@sailgp/sailgp-shared-components';
import markdown from '../../lib/markdown-parser';
import './CookieBanner.scss';
import Toggle from 'react-toggle';

class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      redirectToCookiesPage: false,
      isToggle: true,
      acceptedCookies: [
        '_gcl_au',
        '_ga',
        '_gid',
        '_gat_UA-126989818-1',
        '__adroll_consent_params',
        '__adroll_fpc',
        '__ar_v4',
        '_fbp',
        '_hjAbsoluteSessionInProgress',
        '_hjFirstSeen',
        '_hjIncludedInPageviewSample',
        '_hjSessionUser_2006152',
        '_hjSession_2006152',
        '_hjIncludedInSessionSample'
      ],
      rejectedCookies: []
    };
    this.action = props.action || (() => {});
    this.data = props.data;
    this.cookieName = 'sgp-cp-acceptance';
    this.acceptCookies = this.acceptCookies.bind(this);
    this.learnMore = this.learnMore.bind(this);
    this.eachCookies = this.eachCookies.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
    this.removeCookie = this.removeCookie.bind(this);
    this.selectCookieValue = this.selectCookieValue.bind(this);
    this.removeCookieValue = this.removeCookieValue.bind(this);
    this.acceptSelectedCookies = this.acceptSelectedCookies.bind(this);
    this.deleteCookie = this.deleteCookie.bind(this);
  }

  componentDidMount() {
    const { cookieProvider } = this.props;

    //TODO: look up more react specific method of cookie lookup
    this.setState({
      isVisible: cookieProvider.cookies[this.cookieName] !== 'true'
    });
  }

	setCookie(name, value) {
		const { cookieProvider } = this.props;
		const expires = new Date();
		expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
		cookieProvider.set(name, value, { path: '/', expires: expires });
	}
	deleteCookie(name) {
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=..sailgp.com;';
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=sailgp.com;';
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.stage.sailgp.com;';
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=stage.sailgp.com;';
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
	}

  removeCookie() {
    this.setCookie('sgp-cp-acceptance', 'true');
    let allCookies = [
      '_gcl_au',
      '_ga',
      '_gid',
      '_gat_UA-126989818-1',
      '__adroll_consent_params',
      '__adroll_fpc',
      '__ar_v4',
      '_fbp',
      '_hjAbsoluteSessionInProgress',
      '_hjFirstSeen',
      '_hjIncludedInPageviewSample',
      '_hjSessionUser_2006152',
      '_hjSession_2006152',
      '_hjIncludedInSessionSample'
    ];
    let arrayLength = allCookies.length;
    for (let i = 0; i < arrayLength; i++) {
      this.deleteCookie(allCookies[i]);
    }
    this.setState({
      isVisible: false
    });
  }

  removeCookieValue(cookieName) {
    switch (cookieName) {
      case 'GOOGLE ANALYTICS':
        let filteredAnalyticsCookies = this.state.acceptedCookies.filter(
          e => e == '_gcl_au' || e == '_ga' || e == '_gid' || e == '_gat_UA-126989818-1'
        );
        this.setState({
          rejectedCookies: this.state.rejectedCookies.concat(filteredAnalyticsCookies)
        });
        break;

      case 'ADROLL':
        let filteredAdrollCookies = this.state.acceptedCookies.filter(e => e == '__adroll_consent_params' || e == '__adroll_fpc' || e == '__ar_v4');
        this.setState({
          rejectedCookies: this.state.rejectedCookies.concat(filteredAdrollCookies)
        });
        break;

      case 'HOTJAR':
        let filteredHotjarCookies = this.state.acceptedCookies.filter(
          e =>
            e == '_hjAbsoluteSessionInProgress' ||
            e == '_hjFirstSeen' ||
            e == '_hjIncludedInPageviewSample' ||
            e == '_hjSessionUser_2006152' ||
            e == '_hjSession_2006152' ||
            e == '_hjIncludedInSessionSample'
        );
        this.setState({
          rejectedCookies: this.state.rejectedCookies.concat(filteredHotjarCookies)
        });
        break;

      case 'FACEBOOK':
        let filteredFacebookCookies = this.state.acceptedCookies.filter(e => e == '_fbp');
        this.setState({
          rejectedCookies: this.state.rejectedCookies.concat(filteredFacebookCookies)
        });
        break;
    }
  }
  selectCookieValue(cookieName) {
    switch (cookieName) {
      case 'GOOGLE ANALYTICS':
        let filteredAnalyticsCookies = this.state.rejectedCookies.filter(
          e => e != '_gcl_au' && e != '_ga' && e != '_gid' && e != '_gat_UA-126989818-1'
        );
        this.setState({
          rejectedCookies: filteredAnalyticsCookies
        });
        break;

      case 'ADROLL':
        let filteredAdrollCookies = this.state.rejectedCookies.filter(e => e != '__adroll_consent_params' && e != '__adroll_fpc' && e != '__ar_v4');
        this.setState({
          rejectedCookies: filteredAdrollCookies
        });
        break;

      case 'HOTJAR':
        let filteredHotjarCookies = this.state.rejectedCookies.filter(
          e =>
            e != '_hjAbsoluteSessionInProgress' &&
            e != '_hjFirstSeen' &&
            e != '_hjIncludedInPageviewSample' &&
            e != '_hjSessionUser_2006152' &&
            e != '_hjSession_2006152' &&
            e != '_hjIncludedInSessionSample'
        );
        this.setState({
          rejectedCookies: filteredHotjarCookies
        });
        break;

      case 'FACEBOOK':
        let filteredFacebookCookies = this.state.rejectedCookies.filter(e => e != '_fbp');
        this.setState({
          rejectedCookies: filteredFacebookCookies
        });
        break;
    }
  }
  acceptCookies() {
    this.setCookie(this.cookieName, 'true');
    this.action(this.data.continueLabel);
    this.setState({
      isVisible: false
    });
  }

  learnMore() {
    this.action(this.data.learnMoreLabel);
    this.setState({
      redirectToCookiesPage: true
    });
  }

  eachCookies(e, cookieName) {
    e.target.checked ? this.selectCookieValue(cookieName) : this.removeCookieValue(cookieName);
    this.setState({
      isToggle: !this.state.isToggle
    });
  }

  acceptSelectedCookies() {
    let allCookies = this.state.rejectedCookies;
    let arrayLength = allCookies.length;
    for (let i = 0; i < arrayLength; i++) {
      this.deleteCookie(allCookies[i]);
    }
  }

  saveAndClose() {
    this.setCookie('sgp-cp-acceptance', 'true');
    this.acceptSelectedCookies();
    this.setState({
      isVisible: false
    });
  }

  renderRedirect() {
    if (this.state.redirectToCookiesPage && this.props.data.cookiePageUrl) {
      return <Redirect to={this.props.data.cookiePageUrl} />;
    } else {
      return null;
    }
  }

  render() {
    const pageUrl = this.props.data.pageUrl;
    const isCookiesUrl = pageUrl.includes(this.props.data.cookiePageUrl);
    if (this.state.isVisible && isCookiesUrl !== true) {
      return (
        <section className="c-cookie-banner">
          {this.renderRedirect()}
          <div className="c-cookie-banner_modal">
            <div className="c-cookie-banner_modal_inner">
              <div dangerouslySetInnerHTML={{ __html: markdown(this.data.cookieMessage) }} />
            </div>

            <Button variant="primary" className="top-button" onClick={this.acceptCookies}>
              {this.data.continueLabel}
            </Button>
            <Button variant="secondary" className="top-button" onClick={this.removeCookie}>
              {this.data.rejectLabel}
            </Button>
            <div className="c-cookie-banner_modal_inner">
              <div className="modal-scroll">
                {this.props.data.cookieDescription &&
                  this.props.data.cookieDescription.map(item => (
                    <div key={item.contentfulId}>
                      <div className="cookies-toggle-button">
                        <h3>{item.heading}</h3>
                        {item.heading === 'NECESSARY COOKIES' ? null : (
                          <label>
                            <Toggle
                              defaultChecked={this.state.isToggle}
                              onChange={e => {
                                this.eachCookies(e, item.heading);
                              }}
                              icons={false}
                            />
                          </label>
                        )}
                      </div>
                      <div className="cookie-text">
                        <p>{item.content}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {this.props.data.cookieDescription && (
              <Button variant="primary" className="bottom-button" onClick={this.saveAndClose}>
                {this.data.saveAndCloseLabel}
              </Button>
            )}
            <Button
              variant="secondary"
              className={this.props.data.cookieDescription ? 'bottom-button' : 'bottom-button learn-more'}
              onClick={this.learnMore}
            >
              {this.data.learnMoreLabel}
            </Button>
          </div>
          <div className="c-cookie-banner_background"></div>
        </section>
      );
    } else {
      return null;
    }
  }
}

CookieBanner.propTypes = {
  cookieProvider: PropTypes.object,
  data: PropTypes.object,
  action: PropTypes.func
};

export default CookieBanner;

