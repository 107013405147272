import React, { useState, useEffect } from "react";

import DBLeaderboard from "../DBLeaderboard";
import "./DBLeaderboardTabs.scss";

const DBLeaderboardTabs = ({ data, widget }) => {

	const [activeTabId, setActiveTabId] = useState(0);
	const [activeLeaderboard, setActiveLeaderboard] = useState({});

	useEffect(() => {
		setActiveLeaderboard(data.items[activeTabId]);
	}, [activeTabId]);
	return (
		data ? <div className="c-leaderboard-tab-container DBleaderboard-tab-container">
			<div className='leaderboard-heading-inner'>
				<h2 className={`c-leaderboard-tab-container__title ${data.items.length > 1 ? 'c-leaderboard-tab-container__title--black' : 'c-leaderboard-tab-container__title--gold'}`}>
					{data.title ? data.title.toUpperCase() : ''}
				</h2>
				<div className='rounded-corner'>
					<svg width="65" height="43" viewBox="0 0 65 43" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 43V0.5C33.3333 4.16667 90.3 11.5 51.5 11.5C12.7 11.5 1 35.1667 0 43Z"
							  fill="#003530"/>
					</svg>
				</div>
			</div>

			<ul className={`c-leaderboard-tab-container__list ${widget ? 'c-leaderboard-tab-container__list--widget' : ''}`}>
				{data && data.items.length > 1 && data.items.map((leaderboard, index) => {
					return <li key={`${leaderboard.tabContainerTitle}_${index}`}
							   className="c-leaderboard-tab-container__list--item">
						<a className={`c-leaderboard-tab-container__tab-heading ${activeTabId === index ? "c-leaderboard-tab-container__tab-heading--selected" : ''}`}
						   onClick={() => setActiveTabId(index)}
						>
							{leaderboard.tabContainerTitle ? leaderboard.tabContainerTitle.toUpperCase() : (leaderboard.heading ? leaderboard.heading.toUpperCase() : '')}						</a>
					</li>
				})}
			</ul>
			{Object.keys(activeLeaderboard).length > 0 ?
				<DBLeaderboard data={activeLeaderboard} widget={widget}/> : null}
		</div> : null
	);
};

export default DBLeaderboardTabs;
