const liveFeedTweetUrl = (title, websiteUrl, embedType, embedId) => {
	const baseUrl = 'https://twitter.com/intent';
	let url = `${baseUrl}/tweet?text=${title ? encodeURIComponent(title) : ''}&url=${websiteUrl || ''}`;
	if (embedType && embedType === 'Twitter') {
		url = `${baseUrl}/retweet?tweet_id=${embedId || ''}`;
	}
	return url;
};

export default liveFeedTweetUrl;
