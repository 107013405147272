import {getSsoAPIURL} from "../../../lib/content-api-utils";
import {fetchPageData} from '../../../utils/fetchApiData';

export const verifyToken = async (verificationToken) => {
	const Token = decodeURIComponent(verificationToken)

	const verifyTokenConfig  = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ Token })
	};
	try {
		const verifyTokenAPIURL = await getSsoAPIURL('user/verify-user')

		const fetchResponse = await fetch( verifyTokenAPIURL, verifyTokenConfig);
		return fetchResponse;
	} catch (error) {
		return error;
	}
}
