import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tabs} from '@sailgp/sailgp-shared-components';
import pageComponentsList from '../../containers/ComponentsList';
import UserContext from '../../context/UserContext';

export class TabContainer extends Component {
	static contextType = UserContext

	constructor(props) {
		super(props);
		this.switchTabNavigation = this.switchTabNavigation.bind(this);
	}

	//required for dock
	async switchTabNavigation(tabState) {
	}


	render() {
		return <Tabs
			switchTabNavigation={this.switchTabNavigation}
			tabComponents={pageComponentsList.tabComponents}
			data={this.props.data}
		/>

	}
}

TabContainer.propTypes = {
	data: PropTypes.object,
};

TabContainer.defaultProps = {
	data: {},
};
