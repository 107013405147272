

import { fetchGetData } from "../../utils/fetchApi";
import { toast } from 'react-toastify';
import './DeleteUser.scss';
import {getSsoAPIURL} from '../../lib/content-api-utils';

export async function MyAccountDeleteUser(reset, getValues) {
	const deleteUrl = await getSsoAPIURL("user/delete-account");
	if (getValues("firstName") == "" || getValues("lastName") == "") {
		toast.error("Please fill the form completely", { position: toast.POSITION.TOP_CENTER });
 		return;
	}
	const deleteData = await fetchGetData(deleteUrl, "GET", true);
	let resData;
	if(deleteData.status == 200){
		resData = "User deleted successfully."
	}else{
		resData = "Validation Error."
	}
	return resData;
}
