import React from "react";
import { Controller, useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import markdown from '../../../lib/markdown-parser';

const EditControl = ({
						 fieldData,
						 fieldValue,
						 userData,
						 setUserData,
						 countryData,
						 teamData,
						 roleChange,
						 UserPermissionLevels
					 }) => {

	const {register, setValue, getValues, control} = useFormContext();
	const field = fieldData;


	if(field.apiField === "profileInfo") {
		const valueToSet = sessionStorage.getItem("userRole") || fieldValue
		setValue(field.apiField, valueToSet);
	} else if(field.apiField === "dateOfBirth") {
		if(fieldValue) setValue(field.apiField, new Date(fieldValue))
	} else {
		setValue(field.apiField, fieldValue);
	}

	const onUpdateField = e => {
		setValue(e.target.name, e.target.value, true);
		userData[e.target.name] = e.target.value;
		setUserData(userData);
	};

	let regExFlag = field.regExpFlags;

	if (regExFlag != undefined) {
		regExFlag = regExFlag.join('');
	}

	let config = {
		required: field.required,
		pattern: new RegExp([field.validationRegex].toString(), regExFlag)
	};

	const mapGenderOptions = {
		'FEMALE': 'Female',
		'MALE': 'Male',
		'NONBINARY': 'Non-binary',
		'OTHER': 'Other',
		'PREFERNOT': 'Prefer not to identify',
	};

	switch (field.type) {
		case "text":
			return (
				<>
					<input
						type="text"
						name={field.apiField}
						autoComplete="off"
						{...register(field.apiField, config)}
						onChange={onUpdateField}
					/>
				</>
			);

		case "email":
			return (
				<input
					type="text"
					name={field.apiField}
					{...register(field.apiField, config)}
					onChange={onUpdateField}
					readOnly
				/>
			);

		case "date":
			return (
				<input
					type="date"
					name={field.apiField}
					autoComplete="off"
					max={format(new Date().setFullYear(new Date().getFullYear() - 16), 'yyyy-MM-dd')}
					{...register(field.apiField, config)}
					onChange={onUpdateField}
				/>
			);
		case "dateOfBirth":
			return (
				<Controller control={control} name={field.apiField} render={({field: {onChange, value}}) => {
					return <DatePicker
						type="date"
						name={field.apiField}
						maxDate={new Date(new Date().setFullYear(new Date().getFullYear()-16))}
						minDate={new Date(new Date().setFullYear(new Date().getFullYear()-120))}
						selected={value}
						showMonthDropdown
						showYearDropdown
						dropdownMode='select'
						dateFormat={'dd/MM/yyyy'}
						onChange={(date) => onChange(date)}
						placeholderText={field.placeholder}
						onKeyDown={(e) => {
							e.preventDefault();
						}}
						required={field.required}
					/>
				}} />
			);

		case "textarea":
			return (
				<textarea className="text-area"> </textarea>
			);

		case "number":
			return (
				<input
					type="number"
					name={field.apiField}
					{...register(field.apiField, config)}
					onChange={onUpdateField}
				/>
			);

		case "checkbox":
			return (
				<>
					<div className="checkbox-wrapper">
						<input
							type="checkbox"
							name={field.apiField}
							{...register(field.apiField, config)}
							className="checkbox-custom"
						/>
						<label htmlFor={field.apiField}
							   dangerouslySetInnerHTML={{__html: markdown(field.miniContent)}}/>
					</div>
				</>

			);
		case "singleCheckbox":
			return (
				<>
					{
						field.options != undefined &&
						field.options.map((option, i) => (
							<>
								<div className="checkbox-wrapper">
									<input
										type="checkbox"
										value={i}
										name={field.apiField}
										onClick={(e) => {
											setValue(e.target.name, e.target.value, {shouldValidate: true});
										}}
										{...register(field.apiField, config)}
										className="checkbox-custom"
									/>
									<label htmlFor={field.apiField}>{option}</label>
									<br/>
								</div>
							</>
						))
					}
				</>
			);
		case "radio":
			return (
				<>
					{
						field.options != undefined &&
						field.options.map((option, i) => (
							<>
								<input
									type="radio"
									value={option}
									name={field.apiField}
									{...register(field.apiField, config)}
									className="radio-button"
								/>
								<label>{option}</label>
							</>
						))
					}
				</>
			);
		case "select": {
			// Gender field has a hard-coded value/display name mapping
			if (field.apiField === 'gender') {
				return (
					<select
						{...register(field.apiField, config)}
						name={field.apiField}
						id={field.apiField}
						onChange={onUpdateField}
					>
						<option
							value=""
							className="select-option"
						>
							-- Please select --
						</option>
						{
							field.options &&
							field.options.map((o) => (
								<option
									key={`${field.contentfulId}_${o}`}
									value={o}
									className="select-option"
								>
									{mapGenderOptions[o] || o}
								</option>
							))
						}

					</select>
				);
			}
			return (
				<select
					{...register(field.apiField, config)}
					name={field.apiField}
					id={field.apiField}
					onChange={onUpdateField}
				>
					<option
						value=""
						className="select-option"
					>
						-- Please select --
					</option>
					{
						field.options != undefined &&
						field.options.map((o, index) => (
							<option
								key={index}
								value={o}
								className="select-option"
							>
								{o}
							</option>
						))
					}

				</select>
			);
		}
		case 'country': {
			if (!countryData) {
				return;
			}
			return (
				<select
					{...register(field.apiField, config)}
					name={field.apiField}
					id={field.apiField}
					onChange={onUpdateField}
				>
					{
						countryData &&
						countryData.map((o, index) => (
							<option
								key={index}
								value={o.iso}
								className="select-option"
							>
								{o.name}
							</option>
						))
					}

				</select>
			);
		}

		case "role": {
			if (userData.userPermissionLevel >= 3) {
				return
			}

			const roleValues = []

			field.options.forEach(role => {
				if (UserPermissionLevels[role] === userData.userPermissionLevel) {
					for (const property in UserPermissionLevels) {
						if (UserPermissionLevels[property] >= userData.userPermissionLevel) {
							roleValues.push(property)
						}

					}
				}
			})

			return (
				<>
					<div className="radio-button-group">
						{
							field.options &&
							roleValues.map((option) => (
								<div key={field.contentfulId + '_' + option}
									 className="radio-button-wrapper"><input
									type="radio"
									value={option}
									name={field.apiField}
									{...register(field.apiField, config)}
									className="radio-button"
									onChange={roleChange}
								/> <label htmlFor={field.apiField}>{option}</label></div>
							))
						}
					</div>
				</>
			);
		}


		case "teams": {
			return (
				<select
					{...register(field.apiField, config)}
					name={field.apiField}
					id={field.apiField}
					onChange={onUpdateField}
				>
					{
						teamData &&
						teamData.map((o, index) => (
							<option
								key={index}
								value={o.code}
								className="select-option"
							>
								{o.name}
							</option>
						))
					}

				</select>
			);
		}
		default:
			return <></>;
	}
};

export default React.memo(EditControl);
