import {
	ActionBanner,
	TeamListing,
	FeaturedContent,
	PromoContent,
	EditorialText,
	Gallery,
	MemoizedAccordion as Accordion,
	EditorialProfile as Person,
	EditorialSummaryContainer as SummaryBox,
	MemoizedExternalVideo as ExternalVideo,
	EditorialCTA as EditorialCta,
	EditorialCTAContainer,
	EditorialHeader,
	MemoizedIFrameContent as IFrameContent,
	EditorialImage as Image,
	MemoizedAdvertising as Advert,
	RaceHighlight,
	MemoizedHeroBanner as HeroBanner,
	NextAndLastRace
} from '@sailgp/sailgp-shared-components';


import {ContentHubContainer} from '../../components/ContentHub/ContentHubContainer';
import {ContentHubMiniContainer} from '../../components/ContentHubMini/ContentHubMiniContainer';
import {TabContainer} from '../../components/Tabs/TabsContainer';
import {TeamContainer as Team} from '../../components/Team/teamContainer';
import LeaderboardContainer from '../LeaderboardContainer/LeaderboardContainer';
import LiveFeed from './../../components/LiveFeed';
import RaceSchedule from './../../components/RaceSchedule';
import RaceEvent from './../../components/RaceEvent';
import BroadcastVideo from './../../components/BroadcastVideo';
import Table from './../../components/Table/TableContainer';
import HowToWatch from './../../components/HowToWatch/HowToWatchContainer';
import {RaceCalenderContainer as RaceEventListing} from './../../components/RaceCalendar/RaceCalenderContainer';
import PressConference from '../../components/PressConference';
import FormGroup from '../../components/FormGroup';
import ContactListing from '../../components/ContactListing';
import DevModule from '../../components/DevModule';
import PressConferenceAdmin from '../../components/DevModule/PressConferenceAdmin';
import PhotoShelterGalleryContainer from '../../components/PhotoshelterGallery/PhotoShelterGalleryContainer';
import PhotoBoatAdmin from '../../components/DevModule/PhotoBoatAdmin';
import UserRequestAdmin from "../../components/DevModule/UserRequestAdmin";
import { ConfigurableGridContainer } from '../ConfigurableGridContainer';
import CustomTextContainer from '../../components/CustomTextContainer';
import MediaHubCTA from '../../components/MediaHubCTA/MediaHubCTA';

const allPageComponentsList = {
	NextAndLastRace,
	EditorialText,
	SummaryBox,
	'Leaderboard': LeaderboardContainer,
	HeroBanner,
	LiveFeed,
	RaceSchedule, //todo legacy code possibly and can be removed in future releases.
	TabContainer,
	'Gallery' : PhotoShelterGalleryContainer,
	Accordion,
	Team,
	'ContentHub': ContentHubContainer,
	'FeaturedContentContainer': FeaturedContent,
	Image,
	Person,
	'MiniHub': ContentHubMiniContainer,
	'NanoHub': ContentHubMiniContainer,
	RaceEventListing,
	Advert,
	ExternalVideo,
	PromoContent,
	IFrameContent,
	RaceEvent,
	TeamListing,
	RaceHighlight,
	'RaceLiveStream': BroadcastVideo,
	'EditorialCta': EditorialCTAContainer,
	Table,
	HowToWatch,
	ActionBanner,
	PressConference: PressConference,
	FormGroup,
	ContactListing,
	DevModule,
	ConfigurableGrid: ConfigurableGridContainer
};

const editorialComponentsList = {
	HeroBanner,
	'RaceLiveStream': BroadcastVideo,
	Table,
	RaceSchedule,
	'Leaderboard': LeaderboardContainer,
	RaceEvent,
	RaceHighlight,
	Advert,
	Image,
	IFrameContent,
	RaceEventListing,
	ActionBanner,
	PromoContent,
	EditorialHeader,
	EditorialText,
	SummaryBox,
	ExternalVideo,
	'Gallery': PhotoShelterGalleryContainer,
	Accordion,
	Team,
	FeaturedContentContainer: FeaturedContent,
	Person,
	'NanoHub': ContentHubMiniContainer,
	'EditorialCta': EditorialCTAContainer,
	'MiniHub': ContentHubMiniContainer,
	TabContainer,
	FormGroup,
	ContactListing,
	DevModule
};

const tabComponentsList = {
	Accordion,
	EditorialText,
	Person,
	SummaryBox,
	EditorialCta,
	Team,
	Gallery,
	'MiniHub': ContentHubMiniContainer,
	'NanoHub': ContentHubMiniContainer
}

const configurableGridComponentsList = {
	EditorialText,
	Image,
	FormGroup,
	DevModule,
	ActionBanner,
	CustomTextContainer,
	Accordion,
	EditorialCta: EditorialCTAContainer,
	MediaHubCTA
};


const devModuleList = {
	otherComponents: {
		FormGroup
	},
	'photoBoatAdmin': PhotoBoatAdmin,
	'pressConferenceAdmin': PressConferenceAdmin,
	'userRequestAdmin': UserRequestAdmin
}

export default {
	allPageComponents: allPageComponentsList,
	editorial: editorialComponentsList,
	tabComponents: tabComponentsList,
	devModuleList: devModuleList,
	configurableGridComponents: configurableGridComponentsList
}

