import React, {useState, useEffect, useRef, useCallback} from "react";
import './RolexCountdownWidget.scss';

export default ({ dateFrom }) => {
	const initialState = {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	}

	const interval = useRef();
	const [state, setState] = useState(initialState);

	const calculateCountdown = (endDate) => {
		let diff = (Date.parse(`${new Date(endDate)}`) - Date.parse(`${new Date()}`)) / 1000;

		// clear countdown when date is reached
		if (diff <= 0) return false;

		const timeLeft = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		};

		// calculate time difference between now and expected date
		if (diff >= 86400) { // 24 * 60 * 60
			timeLeft.days = Math.floor(diff / 86400);
			diff -= timeLeft.days * 86400;
		}

		if (diff >= 3600) { // 60 * 60
			timeLeft.hours = Math.floor(diff / 3600);
			diff -= timeLeft.hours * 3600;
		}

		if (diff >= 60) {
			timeLeft.minutes = Math.floor(diff / 60);
			diff -= timeLeft.minutes * 60;
		}

		timeLeft.seconds = diff;

		return timeLeft;
	};

	const stop = () => {
		clearInterval(interval.current);
	};

	const getNextRace = useCallback(async () => {
		try {

			let diff = -1;
			if (dateFrom) {
				diff = (new Date(dateFrom).getTime() - new Date().getTime()) / 1000;
			}
			if (diff > 0) {
				interval.current = setInterval(() => {
					const date = calculateCountdown(dateFrom);
					date ? setState({
						days: date.days,
						hours: date.hours,
						minutes: date.minutes,
						seconds: date.seconds,

					}) : stop();
				}, 1000);
			}
		} catch (error) {
			console.warn(error);
		}
		return () => {
			clearInterval(interval.current);
			stop();
		};
	}, []);

	useEffect(() => {
		getNextRace();
		return () => {
			stop();
		};
	}, [getNextRace]);



	const {days, hours, minutes, seconds} = state;
	return (
		<div className="rolex-watch-container">
			<ul>
				{days > -1 && <li><span>{days}</span>days</li>}
				<li><span>{hours}</span>Hours</li>
				<li><span>{minutes}</span>Minutes</li>
				<li><span>{seconds}</span>Seconds</li>
			</ul>
		</div>
	);
};
