import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import {ScreenLoadingOverlay} from '@sailgp/sailgp-shared-components';
import TableComponent from './UserRequestTable';

export default function PastUser(props) {
	const {
		tableLayout,
		columns,
		tableFirstPosition,
		pageSize,
		tableSecondPosition,
		itemRender,
		tableDataSource,
		userRolesButton,
		rejectModalButton,
		isModalOpen,
		handleCancel,
		changeRole,
		isRejectModalOpen,
		userRole,
		rejectUserByAdmin,
		loading
	} = props;

	return (
		<React.Fragment>
			{loading ?
				<TableComponent
					tableLayout={tableLayout}
					columns={columns}
					tableFirstPosition={tableFirstPosition}
					pageSize={pageSize}
					tableSecondPosition={tableSecondPosition}
					itemRender={itemRender}
					tableDataSource={tableDataSource}
					className="table-layout"
				/> :
				<ScreenLoadingOverlay active={true} />
			}
			<Modal
				title={`User Role ( Current Role : ${userRole})`}
				open={isModalOpen}
				onCancel={handleCancel}
				className="user-requests-user-role-assignment"
			>
				{userRolesButton.map(btn => {
					return (
						<Button key={btn.id} type="primary" htmlType={btn.htmlType} onClick={() => changeRole(btn.id)} className="button-bold-italic">
							{btn.label}
						</Button>
					);
				})}
			</Modal>
			<Modal title="Do you want to reject this user?" open={isRejectModalOpen} onCancel={handleCancel} className="user-requests-user-role-assignment">
				{rejectModalButton.map(btn => {
					return (
						<Button
							key={btn.id}
							type="primary"
							htmlType={btn.htmlType}
							onClick={() => rejectUserByAdmin(btn.id)}
							className="button-bold-italic"
						>
							{btn.label}
						</Button>
					);
				})}
			</Modal>
		</React.Fragment>
	);
}
PastUser.propTypes = {
	tableLayout: PropTypes.string,
	columns: PropTypes.array,
	tableFirstPosition: PropTypes.string,
	pageSize: PropTypes.array,
	tableSecondPosition: PropTypes.string,
	itemRender: PropTypes.func,
	tableDataSource: PropTypes.array,
	userRolesButton: PropTypes.array,
	rejectModalButton: PropTypes.array,
	isModalOpen: PropTypes.bool,
	handleCancel: PropTypes.func,
	changeRole: PropTypes.func,
	isRejectModalOpen: PropTypes.bool,
	loading: PropTypes.bool,
	userRole: PropTypes.object,
	rejectUserByAdmin: PropTypes.func
};
