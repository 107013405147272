import React, {useEffect, useState} from 'react';
import './PhotoShelterGalleryContainer.scss';
import Modal from 'react-modal';
import {IoMdClose} from 'react-icons/io';
import {Button} from '@sailgp/sailgp-shared-components';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import {Grid, Row, Cell} from '../Grid';
import { fetchImageData, fetchPhotoShelterGalleryData } from './api/PhotoShelterGalleryAPI';
import GalleryCarousel from './GalleryCarousel';

export default function PhotoShelterGalleryContainer(props) {

	let dataPerPage = 16;
	const [getgalleryData, setgalleryData] = useState(dataPerPage);
	const [next, setNext] = useState(dataPerPage);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [imgIndex, setImgIndex] = useState('');
	const [imgDownloadUrl, setImgDownloadUrl] = useState('');
	const [hdImgDownloadUrl, setHdImgDownloadUrl] = useState('');
	const [imageData, setImageData] = useState({})

	const handleMore = () => {
		setNext(next + dataPerPage);
	};

	if (props.data && props.data.photoshelterGalleryId == null) {
		return <></>;
	}

	useEffect(() => {
		fetchPhotoShelterGalleryData(
			props.data.photoshelterGalleryId
		).then((res) => {
			if(res.data && res.data.GalleryMedia) setgalleryData(res.data.GalleryMedia);
		})
	}, []);

	const getImageData = async (imageID) => {
		const imageRes = await fetchImageData(imageID)
		setImageData(imageRes)
	}

	const openModal = (v, i) => {
		setIsOpen(true);
		setImgIndex(i)
		getImageData(v.GalleryImage.image_id)
	}



	function imgInfo(imgObj) {
		let hdQtyImgUrl = `https://sailgp.photoshelter.com/galleries/${imgObj.gallery_id}/${imgObj.image_id}`;
		setHdImgDownloadUrl(hdQtyImgUrl);
		setImgDownloadUrl(imgObj.ImageLink.link)
	}

	function closeModal() {
		setIsOpen(false);
	}

	function forceDownload(url) {
        var fileName = url.substring(url.lastIndexOf('/') + 1, url.length)
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.send();
    }

	return (
		<section className='photoshelter-gallery'>
			{props.data.name && <h2 className='gallery-heading'>{props.data.name}</h2>}
			<Grid >
				<Row className="gallery-bottom-gradient">
					{getgalleryData && getgalleryData.length > 0 &&
					getgalleryData.slice(0, next).map((value, index) => {
						return (
							<Cell key={index} columns={3}>
								<div key={index} className='img-wrapper' onClick={() => openModal(value, index)}>
									<img src={value.GalleryImage.ImageLink.base} className="gallery-img"
										 alt="landing_image"/>
								</div>
							</Cell>

						)
					})}
				</Row>

			</Grid>
			{next < getgalleryData.length && (
				<Button onClick={() => {
					handleMore();
				}} className="button-img-gallery">
					<span>Load More</span>
				</Button>
			)}
			<Modal className="photoshelter-Modal" isOpen={modalIsOpen} onRequestClose={closeModal} ariaHideApp={false}>
				<div className='carousel-wrapper'>
					<button onClick={closeModal} className='modal-close-btn'>
						<IoMdClose className='modal-close-btn__icon'/>
					</button>
					<GalleryCarousel imgInfo={imgInfo} getgalleryData={getgalleryData} imgIndex={imgIndex} getImageData={getImageData}/>
					<div className='metadata-info-container'>
						<div className='metadata-info'>
							<h3>{imageData.headline && imageData.headline.toUpperCase()}</h3>
							<p className='metadata-info__text'>{imageData.caption}</p>
							<div className='metadata-info__row'>
								<div className='metadata-info__row--column'>
									<p className='metadata-info__text'>Filename</p>
									<p className='metadata-info__text metadata-info__text--bold'>{imageData.fileName}</p>
									<p className='metadata-info__text'>Credit</p>
									<p className='metadata-info__text metadata-info__text--bold'>{imageData.credit}</p>
								</div>
								<div className='metadata-info__row--column'>
									<p className='metadata-info__text'>Image Size</p>
									<p className='metadata-info__text metadata-info__text--bold'>{imageData.imageSize}</p>
									<p className='metadata-info__text'>Copyright</p>
									<p className='metadata-info__text metadata-info__text--bold'>{imageData.copyright}</p>
								</div>
								<div className='metadata-info__row--column'>
									<p className='metadata-info__text'>Date Taken</p>
									<p className='metadata-info__text metadata-info__text--bold'>{moment(imageData.sdate).format('DD MMM YYYY')}</p>
									<p className='metadata-info__text'>Uploaded</p>
									<p className='metadata-info__text metadata-info__text--bold'>{moment(imageData.mtime).format('DD MMM YYYY')}</p>
								</div>
							</div>
							<div className='metadata-info__rights'>
								<p className='metadata-info__text'>Usage Rights</p>
								<p className='metadata-info__text metadata-info__text--bold'>{imageData.rights}</p>
							</div>

						</div>
						<div className='download-info'>
							<a href={hdImgDownloadUrl} target='_blank' className='image-name'>Click here to download HD quality image</a>
							<div className='download-wrap'>
								<FaDownload className='download-icon'/>
								<button className='download-btn' onClick={() => forceDownload(imgDownloadUrl)}>Download</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</section>
	)
}
