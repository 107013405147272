 export const fetchPhotoShelterGalleryData = async (galleryId) => {
	const apiKey = process.env.REACT_APP_PHOTOSHELTER_KEY;
	const initEp = `https://sailgp.photoshelter.com/psapi/v3/gallery/${galleryId}/media?api_key=${apiKey}&fields=*`;
	const extend = {
	  ImageLink: {
		fields: "link,base",
		params: {
		  image_mode: "fit",
		  image_size: "800x800",
		},
	  },
	};
	const extendStr = "extend=" + JSON.stringify(extend);
	const request = initEp + "&" + extendStr;

	try {
	  const response = await fetch(request);
	  return response.json();
	} catch (error) {
	  console.error(error);
	}
	return {};
  };


export const fetchImageData = async (imageId) => {
	const apiKey = process.env.REACT_APP_PHOTOSHELTER_KEY;
	const getImgIptcUrl = `https://sailgp.photoshelter.com/psapi/v3/media/${imageId}/iptc?api_key=${apiKey}&fields=*`
	const getImgDataUrl = `https://sailgp.photoshelter.com/psapi/v3/media/${imageId}?api_key=${apiKey}&fields=*`

	try {
		const responseIptc = await fetch(getImgIptcUrl)
		const responseImg = await fetch(getImgDataUrl)

		const imageRes = await responseImg.json()
		const { data } = await responseIptc.json()

		const fileName = imageRes.data.Media.file_name;
		const sizeMb = (imageRes.data.Media.file_size / 1000000 ).toFixed(2)
		const sizePx = `${imageRes.data.Media.width} x ${imageRes.data.Media.height}`;
		const imageSize = `${sizePx} / ${sizeMb}MB`
		return { imageSize, fileName, ...data.Iptc}
	} catch (error) {
		console.error()
	}
	return {}
}
