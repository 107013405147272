import { Col, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import './table.scss';

export default function TableComponent(props) {
	const { columns, tableDataSource, tableFirstPosition, tableSecondPosition, pageSize, itemRender, tableLayout, className, rowClassName } = props;

	return (
		<Col xl={24} lg={24} md={24} sm={24} xs={24} className={className}>
			<Table
			    tableLayout={tableLayout}
				columns={columns}
				pagination={{
					showSizeChanger: true,
					position: [tableFirstPosition, tableSecondPosition],
					itemRender: itemRender,
					pageSizeOptions: pageSize
				}}
				rowClassName={rowClassName}
				dataSource={tableDataSource}
				scroll={{x:400}}
			/>
		</Col>
	);
}
TableComponent.propTypes = {
	tableLayout: PropTypes.string,
	columns: PropTypes.array,
	tableFirstPosition: PropTypes.string,
	pageSize: PropTypes.array,
	tableSecondPosition: PropTypes.string,
	itemRender: PropTypes.func,
	tableDataSource: PropTypes.array
};
