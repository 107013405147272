import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconWeibo = createIconComponent({
	content: (
		<Fragment>
			<path d="M 22.838 3.819 C 22.469 3.844 22.102 3.893 21.739 3.968 C 20.826 4.206 20.512 5.344 21.175 6.016 C 21.452 6.297 21.848 6.428 22.238 6.367 C 24.347 5.917 26.535 6.622 27.986 8.217 C 29.424 9.819 29.9 12.066 29.235 14.113 C 29.031 14.761 29.388 15.453 30.034 15.663 C 30.682 15.864 31.372 15.508 31.584 14.863 C 32.484 12.077 31.934 8.941 29.834 6.618 C 28.303 4.931 26.164 3.924 23.889 3.819 C 23.539 3.797 23.188 3.797 22.838 3.819 Z M 12.894 7.465 C 10.663 7.705 7.515 9.447 4.748 12.215 C 1.73 15.229 0 18.442 0 21.207 C 0 26.502 6.76 29.705 13.393 29.705 C 22.089 29.705 27.886 24.62 27.886 20.609 C 27.886 18.186 25.875 16.83 24.037 16.262 C 23.588 16.125 23.25 16.054 23.489 15.463 C 24 14.175 24.093 13.058 23.537 12.264 C 22.5 10.777 19.596 10.852 16.34 12.215 C 16.34 12.215 15.33 12.677 15.592 11.863 C 16.093 10.253 16.024 8.897 15.242 8.116 C 14.692 7.56 13.905 7.36 12.893 7.467 Z M 23.689 8.116 C 23.335 8.103 22.982 8.137 22.638 8.217 C 22.07 8.335 21.714 8.948 21.838 9.515 C 21.961 10.082 22.521 10.441 23.087 10.315 C 23.769 10.171 24.538 10.358 25.038 10.915 C 25.537 11.471 25.656 12.202 25.438 12.864 C 25.256 13.419 25.531 14.032 26.086 14.214 C 26.642 14.388 27.261 14.118 27.436 13.563 C 27.894 12.159 27.571 10.617 26.586 9.515 C 25.842 8.685 24.8 8.182 23.687 8.116 Z M 14.094 15.162 C 18.522 15.319 22.089 17.518 22.388 20.559 C 22.732 24.039 18.741 27.281 13.443 27.805 C 8.146 28.331 3.542 25.933 3.198 22.459 C 2.855 18.979 6.902 15.738 12.194 15.213 C 12.856 15.149 13.462 15.138 14.094 15.162 Z M 11.094 18.511 C 9.283 18.674 7.571 19.71 6.797 21.21 C 5.741 23.252 6.784 25.512 9.195 26.257 C 11.688 27.025 14.616 25.864 15.642 23.659 C 16.649 21.503 15.36 19.267 12.893 18.661 C 12.306 18.509 11.698 18.458 11.095 18.511 Z M 9.945 21.81 C 10.158 21.815 10.351 21.883 10.545 21.959 C 11.325 22.278 11.568 23.115 11.095 23.858 C 10.607 24.594 9.576 24.951 8.795 24.607 C 8.028 24.271 7.809 23.427 8.295 22.709 C 8.658 22.171 9.314 21.804 9.945 21.808 Z"/>
		</Fragment>
	),
	height: 32,
	width: 32
});

IconWeibo.displayName = 'IconWeibo';

export default IconWeibo;
