/* global process */

import encryptString from '../utils/encryptString';
import Logger from './logger';

const logger = Logger.get('Analytics');

const _alwaysSendToDefaultTracker = true;

export function event({ eventName, category, action, label, ...args } = {}, trackerNames) {

	if (typeof window !== 'undefined' && !window.ga) {
		return
	}

	if (process.env.BUILD_TARGET === 'server') {
		return;
	}

	if (!category || !action) {
		logger.warn('args.category AND args.action are required in event()');

		return;
	}

	// Required fields
	const fieldObject = {
		eventCategory: category,
		eventAction: action
	};

	// Optional fields
	if (eventName) {
		fieldObject.eventName = eventName;
	}

	if (label) {
		fieldObject.eventLabel = label;
	}

	// Other fields
	Object.keys(args).forEach(key => {
		fieldObject[key] = args[key];
	});

	// Send to Analytics
	send(fieldObject, trackerNames);
}

export function virtualPageView({ page } = {}, trackerNames) {

	if (typeof window !== 'undefined' && !window.ga) {
		return
	}

	if (process.env.BUILD_TARGET === 'server') {
		return;
	}

	if (!page) {
		logger.warn('args.page is required in event()');

		return;
	}

	// Required fields
	const fieldObject = {
		event: '_virtualPageView',
		page,
	};

	// Send to Analytics
	send(fieldObject, trackerNames);
}

const internalGa = (...args) => {
	const cmd = args[0];
	const data = args[1];
	const {eventName, eventCategory, eventAction, eventLabel, ...rest} = data

	if (cmd === 'send') {
		if (data.event && data.event === '_virtualPageView') {
			window.dataLayer.push({
				'event': data.event,
				'page': data.page,
			});
		}
		else {
			window.dataLayer.push({
				'event': eventName,
				'Category': eventCategory,
				'Action': eventAction,
				'Label': eventLabel,
				...rest
			});
		}
	}
}

function _trackCommand(trackerNames, ...args) {
	if (_alwaysSendToDefaultTracker || !Array.isArray(trackerNames)) internalGa(...args);
	if (Array.isArray(trackerNames)) {
		trackerNames.forEach(name => {
			// Implement code for other trackers
			logger.info(name);
		});
	}
}

export function send(fieldObject, trackerNames) {
	_trackCommand(trackerNames, 'send', fieldObject);

	logger.info("called ga('send', fieldObject);");
	logger.info(`with fieldObject: ${JSON.stringify(fieldObject)}`);
	logger.info(`with trackers: ${JSON.stringify(trackerNames)}`);
}

export async function prepareAnalytics(isLoggedIn, userId) {
	const analyticsDetails = { eventName:'user-page',
		category:'user-page',
		action: 'visit-page',
		label: 'visit-page',
		visitorType: 'fan',
		visitorLoginState: 'no',
		pageLocale: navigator.languages[0]}

	if(isLoggedIn) {
		const encryptedEmail = await encryptString(userId)
		analyticsDetails.user_id = encryptedEmail;
		analyticsDetails.visitorLoginState = 'yes'
	}
	event(analyticsDetails)
}

export default {
	event,
	virtualPageView,
	send,
	prepareAnalytics
};
