
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Cell, Row } from '../Grid';
import './zoom.scss';
import { fetchPostData } from '../../utils/fetchApi';
import { fetchPageData } from '../../utils/fetchApiData';

function Zoom(props) {
	const { contentfulData, zoomMeetingNumber, password } = props;
	const sdkKey = 'uBJi1sIkkqBCpB52u7SMlDESRhaxn223HspN'
	const meetingNumber = zoomMeetingNumber
	const passWord = password
	const userName = typeof window !== 'undefined' && window.sessionStorage && JSON.parse(sessionStorage.userDetails).firstName
	const userEmail = typeof window !== 'undefined' && window.sessionStorage && JSON.parse(sessionStorage.userDetails).email
	const zakToken = '';
	const [client, setClient] = useState(null)
	const [ZoomMeetingEmbed, setZoomMeetingEmbed] = useState(null)

	useEffect(() => {
		const initTerminal = async () => {
			const init = await import('@zoomus/websdk/embedded');
			setZoomMeetingEmbed(init);
		}
		initTerminal()
	}, [])

	useEffect(() => {
		return () => {
			if (client && ZoomMeetingEmbed !== null) {
				client.leaveMeeting();
				ZoomMeetingEmbed.default.destroyClient();
				setClient(null)
			}
		};
	}, [client]);

	useEffect(() => {
		if (ZoomMeetingEmbed !== null) {
			const ZoomMtgEmbeddedClient = ZoomMeetingEmbed.default.createClient()
			setClient(ZoomMtgEmbeddedClient)
		}
	}, [ZoomMeetingEmbed])

	function getSignature(e) {
		e.preventDefault();
		const getZoomMeetingAPIURL = fetchPageData(`event/zoomSignature`);
		getZoomMeetingAPIURL.then(url => {
			const zoomPayLoadBody = {
				meetingNumber: meetingNumber,
				role: 0
			};
			const getZoomData = fetchPostData(url, 'POST', zoomPayLoadBody, true);
			return getZoomData
		}).then(res => res.json())
			.then(response => {
				startMeeting(response.data)
			}).catch(error => {
			console.error(error)
		})
	}

	function startMeeting(signature) {
		const meetingSDKElement = document.getElementById('meetingSDKElement');
		if (client !== null) {
			client.init({
				debug: true,
				zoomAppRoot: meetingSDKElement,
				language: 'en-US',
				customize: {
					meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
					toolbar: {
						buttons: [
							{
								text: 'Custom Button',
								className: 'CustomButton',
								onClick: () => {
									console.log('custom button');
								}
							}
						]
					},
					video: {
						isResizable: false,
						viewSizes: {
							default: {
								width: 780,
								height: 515
							},
						}
					}
				}
			});

			client.join({
				signature: signature,
				sdkKey: sdkKey,
				meetingNumber: meetingNumber,
				password: passWord,
				userName: userName,
				userEmail: userEmail,
				zak: zakToken
			})
		}
	}

	return (
		<Row>
			<Cell xlCols={12} lgCols={12} mdCols={6} smCols={2}>
				<div id="meetingSDKElement">
				<button className='btn' onClick={getSignature}>{contentfulData.joinmeeting}</button>
				</div>

			</Cell>
		</Row>
	);
}

Zoom.propTypes = {
	contentfulData: PropTypes.object,
	joinMeeting: PropTypes.func,
	zoomMeetingNumber: PropTypes.number,
	password: PropTypes.string
};

export default Zoom
