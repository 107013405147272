import React from 'react'
import Slider from 'react-slick';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

export default function GalleryCarousel(props) {

    function VertBtnRightt(galleryData) {
        props.imgInfo(props.getgalleryData[galleryData.currentSlide].GalleryImage);
        const { onClick } = galleryData;
        return (
            <FiChevronRight className='arrow right-arrow' onClick={(e) => {
				onClick(e)
				props.getImageData(props.getgalleryData[galleryData.currentSlide+1].GalleryImage.image_id)
			}} />
        );
    }

    function VertBtnLeftt(galleryData) {
        props.imgInfo(props.getgalleryData[galleryData.currentSlide].GalleryImage);
        const { onClick } = galleryData;
        return (
            <FiChevronLeft onClick={(e) => {
				onClick(e)
				props.getImageData(props.getgalleryData[galleryData.currentSlide+1].GalleryImage.image_id)
			}} className="arrow left-arrow" />
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slickGoTo: 4,
        nextArrow: <VertBtnRightt></VertBtnRightt>,
        prevArrow: <VertBtnLeftt></VertBtnLeftt>,
        initialSlide: props.imgIndex
    };

    return (
        <Slider {...settings} >
            {props.getgalleryData && props.getgalleryData.length > 0 && props.getgalleryData.map((value, index) => {
                return (<div key={index}>
                    <img
                        src={props.getgalleryData[index].GalleryImage.ImageLink.link}
                        alt="landing_image" className='carousel-img'
                    />
                </div>
                )
            })}
        </Slider>
    )
}

