import React from 'react';
import './RequireHigherPermissions.scss';
import {Grid} from '../Grid';

function RequireHigherPermissions() {

	return (
		<Grid className="l-grid has-editorial-spacing">
			You do not have permission to view this page. If you believe this is incorrect, please contact your administrator to update your user permissions.
		</Grid>
	)
}

RequireHigherPermissions.propTypes = {

};

export default RequireHigherPermissions
