import React from 'react';
import PropTypes from 'prop-types';

import './Table.scss';
import TableItem from './TableItem';

const Table = ({ title, subtitle, items, alt }) => (
	<div className="c-race-table">
		<caption className="c-race-table__caption">{title || ''} {subtitle || ''}</caption>
		<div className="c-race-table__header-cell c-race-table__header-cell--title">
			{ title && <span className="c-race-table__title">{title}</span> }
			{ subtitle && <span className="c-race-table__subtitle">{subtitle}</span> }
		</div>
		<div className="c-race-table__header-cell">
			Local Time
		</div>
		<div className="c-race-table__header-cell">
			Track Time*
		</div>
		<div className="c-race-table__header-separator" />
		{
			items.map((e, i) => <TableItem key={i} alt={alt} {...e} />)
		}
	</div>
);

Table.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	items: PropTypes.array,
	alt: PropTypes.bool,
};

Table.defaultProps = {
	title: '',
	subtitle: '',
	items: [],
	alt: false,
};

export default Table;
