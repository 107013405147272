import React from 'react'

const UserContext = React.createContext({
	user : {},
	logIn: () => {},
	ssoLogin: () => {},
	logOut: () => {},
	registerUser: () => {},
	updateProfile: () => {},
	resetPassword:() => {},
	setUser : () => {}
})

export default UserContext;
