import React from 'react';
import PropTypes from 'prop-types';
import './CustomTextContainer.scss';
import MediaHubCTA from '../MediaHubCTA/MediaHubCTA';
import {EditorialCTA, Img} from '@sailgp/sailgp-shared-components';

const CustomTextContainer = (props) => {
	const TagType = props.data.tagType;
	const opts = {};

	if (props.data.classNames && props.data.classNames.length) {
		opts.className = ['custom-text-container', ...props.data.classNames].join(' ');
	} else {
		opts.className = 'custom-text-container';
	}

	if(props.data.backgroundImage){
		opts.style={
			'backgroundImage':'url('+props.data.backgroundImage.file.url+')'
		}
	}

	if (props.data.supportInnerHtml) {
		opts.dangerouslySetInnerHTML = { __html: props.data.content };

		return <TagType {...opts} />;
	}

	return <TagType {...opts} >
		{props.data.content}
		{props.data.innerTextComponents &&
		props.data.innerTextComponents.map((comp) => {
			const CompType = comp.contentfulType;
			switch (CompType) {
				case 'CustomTextContainer':
					return <CustomTextContainer data={comp} key={comp.contentfulId}/>;
				case 'MediaHubCta':
					return <MediaHubCTA data={comp} key={comp.contentfulId}/>;
				case 'EditorialCta':
					return <EditorialCTA {...comp} key={comp.contentfulId} isInCustomText={true}/>;
				case 'Image':
					return <Img src={comp.image.file.url}
								alt={comp.label}
								key={comp.contentfulId} />;
				default:
					return <div key={comp.contentfulId} />;
			}
		})}
	</TagType>;
};

CustomTextContainer.propTypes = {
	data: PropTypes.object
};

export default CustomTextContainer;
