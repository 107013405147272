import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageContext from '../../context/PageContext';
import {withNavigation} from './index';
import {SideNavigationDrawer} from '@sailgp/sailgp-shared-components';
import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import UserContext from '../../context/UserContext';
import { fetchPageData } from '../../utils/fetchApiData';
import { fetchGetData } from '../../utils/fetchApi';


const SideNavigationDrawerContainer = ({
										   data,
										   navItems,
										   selectedItem,
										   clearSelectedItem,
										   getSecondaryNavItems,
										   getSelectedNavItem,
										   revealSecondaryNav,
										   onSelectItemHandler,
									   }) => {


	useEffect(() => () => {
		clearAllBodyScrollLocks();
		if(typeof window !== 'undefined' && sessionStorage.getItem("token")){
			const getAllPendingAPIURL = fetchPageData(`admin/pendingUser?limit=`);
			getAllPendingAPIURL.then((apiURL) => {
			//Getting the API URL
			const allPendingData = fetchGetData(apiURL, "GET", true);
			allPendingData
				.then((response) => response.json())
				.then((data) => {
				//Getting the response
				if (data.status === 1) {
					sessionStorage.setItem("totalPendingUser",data.data && data.data.totalResults);
				}
				})
				.catch((error) => {});
			});
		}
		
	}, []);


	const pendingUserCount = typeof window !== 'undefined' && window.sessionStorage && sessionStorage.getItem('totalPendingUser')
    const {isLoggedIn, user, logOut, uploadAvatar} = useContext(UserContext)
	const {isSideDrawerOpen, toggleSideDrawerOpen, isLanguageSelectOpen, closeLanguageSelect} = useContext(PageContext)

	return (
		<SideNavigationDrawer
			revealSecondaryNav={revealSecondaryNav}
			backLabel={data.backLabel}
			closeLabel={data.closeLabel}
			isSideDrawerOpen={isSideDrawerOpen}
			toggleSideDrawerOpen={toggleSideDrawerOpen}
			navItems={navItems}
			selectedItem={selectedItem}
			clearSelectedItem={clearSelectedItem}
			getSecondaryNavItems={getSecondaryNavItems}
			isLanguageSelectOpen={isLanguageSelectOpen}
			closeLanguageSelect={closeLanguageSelect}
			onSelectItemHandler={onSelectItemHandler}
			accountLinks={data.accountLinks}
			user={{isLoggedIn, ...user}}
			logOut={logOut}
			requestNotifications={pendingUserCount}
			uploadAvatar={uploadAvatar}/>
	);
}

SideNavigationDrawerContainer.propTypes = {
	children: PropTypes.any,
	navItems: PropTypes.array,
	selectedItem: PropTypes.any,
	clearSelectedItem: PropTypes.func,
	getSecondaryNavItems: PropTypes.func,
	getSelectedNavItems: PropTypes.func,
	getSelectedNavItem: PropTypes.func,
	revealSecondaryNav: PropTypes.func,
	onSelectItemHandler: PropTypes.func,
	data: PropTypes.object
};

export default memo(withNavigation(SideNavigationDrawerContainer));
