import React, {useState, useEffect, useContext} from 'react';
import { fetchGetData } from '../../../utils/fetchApi';
import TableComponent from './PhotoBoatTable';
import Filter from './Filter';
import './PhotoBoatRequest.scss';
import UserContext from '../../../context/UserContext';
import {fetchPageData} from '../../../utils/fetchApiData';

export default function PhotoBoatAdmin({data}) {
	const [photosignupData, setPhotosignupData] = useState([]);
	const tableDataSource = [];
	const tableFirstPosition = 'bottom';
	const tableSecondPosition = 'left';
	const pageSize = [10, 25, 50, 100];
	const [photosignupDropdownData, setPhotosignupDropdownData] = useState([]);
	const [sortByValue, setSortByValue] = useState('');
	const { user } = useContext(UserContext);

	useEffect(() => {

		const getPhotoSignupListAPIURL = fetchPageData(`question/photosignuplist`);
		getPhotoSignupListAPIURL.then((apiURL) => {
			//Getting the API URL
			const DataList = fetchGetData(apiURL, 'GET', true);
			DataList.then((response) => response.json())
				.then((data) => {
					//setShowLoader(false);
					//Getting the response
					if (data.status === 1) {
						setPhotosignupData(data.data.data);
					}
				})
				.catch((error) => {});
		});

		const getPhotoSignupDropdownListAPIURL =
      fetchPageData(`question/PhotoEvents`);
		getPhotoSignupDropdownListAPIURL.then((apiURL) => {
			//Getting the API URL
			const DropdownList = fetchGetData(apiURL, 'GET', true);
			DropdownList.then((response) => response.json())
				.then((data) => {
					//Getting the response
					if (data.status === 1) {
						setPhotosignupDropdownData(data.data);

					}
				})
				.catch((error) => {});
		});

	}, []);


	if (Object.keys(photosignupData).length > 0) {
		photosignupData.forEach((value, index) => {
			const firstname = value.firstName;
			const lastname = value.lastName;
			const organization = value.organization;
			const phonenumber = value.phone_number;
			const eventname = value.eventName;
			const emailaddress = value.emailAddress;
			const daysofinterest = value.day_interest.toString().split(',');
			tableDataSource.push({
				key: index,
				eventname: [eventname],
				firstname: [firstname],
				lastname: [lastname],
				organization: [organization],
				phonenumber: [phonenumber],
				emailaddress: [emailaddress],
				daysofinterest: [
					daysofinterest.length > 0 &&
                daysofinterest.map((element) => (
                	<ul>
                		<li className="photoboatsignup-requests-list-bullet">
                			{element}
                		</li>
                	</ul>
                )),
				],
			});
		});
	}

	const columns = [
		{
			title: 'Event Name',
			dataIndex: 'eventname',
			key: 'eventname',
		},
		{
			title: 'First Name',
			dataIndex: 'firstname',
			key: 'firstname',
		},
		{
			title: 'Last Name',
			dataIndex: 'lastname',
			key: 'lastname',
		},
		{
			title: 'Email Address',
			dataIndex: 'emailaddress',
			key: 'emailaddress',
		},
		{
			title: 'Organization',
			dataIndex: 'organization',
			key: 'organization',
		},
		{
			title: 'Phone Number',
			dataIndex: 'phonenumber',
			key: 'phonenumber',
		},
		{
			title: 'Days of Interest',
			dataIndex: 'daysofinterest',
			key: 'daysofinterest',
		},
	];

	const itemRender = (_, type, originalElement) => {
		if (type === 'prev') {
			return '<<';
		}
		if (type === 'next') {
			return '>>';
		}
		return originalElement;
	};

	const handleChange = (value) => {
		setSortByValue(value);
		const getPhotoSignupListAPIURL = fetchPageData(
			`question/photosignuplist?filter=${value}`
		);
		getPhotoSignupListAPIURL.then((apiURL) => {
			//Getting the API URL
			const DataList = fetchGetData(apiURL, 'GET', true);
			DataList.then((response) => response.json())
				.then((data) => {
					// showLoader(false);
					//Getting the response
					if (data.status === 1) {
						setPhotosignupData(data.data.data);
					}
				})
				.catch((error) => {});
		});
	};

	return (<div className='photoboatSignupRequest-wrapper'>
		{user.userPermissionLevel <= 2 ? ( photosignupData && photosignupData.length > 0 &&
    <div>
    	<Filter
    		handleChange={handleChange}
    		photosignupData={photosignupData}
    		photosignupDropdownData={photosignupDropdownData}
    	/>
    	<TableComponent
    		tableFirstPosition={tableFirstPosition}
    		tableSecondPosition={tableSecondPosition}
    		tableDataSource={tableDataSource}
    		pageSize={pageSize}
    		columns={columns}
    		itemRender={itemRender}
    	/>
    </div> ) : <p>You don't have permission to view this.</p>}
	</div>

	)
}
