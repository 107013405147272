import React from 'react';
import './DBLeaderboard.scss';

const leaderboardConfig = [
	{
		/* leaderboard */
		requirements: {
			leaderBoardType: 'Season Championship',
			raceType: 'Others'
		},
		content: {
			column: 'points'
		}
	},
	{
		/* race event live*/
		requirements: {
			leaderBoardType: 'Race Event',
			raceType: 'Others',
			isLiveEvent: true
		},
		content: {
			column: 'points'
		}

	},
	{
		/* race event*/
		requirements: {
			leaderBoardType: 'Race Event',
			raceType: 'Others',
			isLiveEvent: false
		},
		content: {
			column: 'medal'
		}

	},
	{
		/* final race*/
		requirements: {
			leaderBoardType: 'Race',
			raceType: 'Final'
		},
		content: {
			column: 'medal'
		}
	},
	{
		/* fleet race*/
		requirements: {
			leaderBoardType: 'Race'
		},
		content: {
			column: 'points'
		}
	},
	{
		/* default event*/
		requirements: {
			useDatabase: true
		},
		content: {
			column: 'medal'
		}
	}
];

let currentLeaderboardConfig;

const defineContent = (props) => {
	const defaultConfig = leaderboardConfig.find(item => item.requirements.useDatabase === true);

	const matchedConfig = leaderboardConfig.find(item => {
		return Object.keys(item.requirements).every(requirement => {
			return props.data[requirement] === item.requirements[requirement];
		});
	});

	return matchedConfig || defaultConfig;

};

const getMedal = (medalType) => {
	const allTypes = {
		'gold': (
			<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='17.5' cy='17.5' r='17.5' fill='#EFBC38' />
				<text x='50%' y='55%' text-anchor='middle' dominant-baseline='middle' font-size='18' font-family='Arial'
					  fill='black'>G
				</text>
			</svg>
		),
		'silver': (
			<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='17.5' cy='17.5' r='17.5' fill='#D9D9D9' />
				<text x='50%' y='55%' text-anchor='middle' dominant-baseline='middle' font-size='18' font-family='Arial'
					  fill='black'>S
				</text>

			</svg>
		),
		'bronze': (
			<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='17.5' cy='17.5' r='17.5' fill='#AE7011' />
				<text x='50%' y='55%' text-anchor='middle' dominant-baseline='middle' font-size='18' font-family='Arial'
					  fill='black'>B
				</text>
			</svg>

		),
		'notype': (
			<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx='17.5' cy='17.5' r='17.5' fill='#C72F0E' />
				<path
					d='M17.5 7L19.8574 14.2553H27.4861L21.3143 18.7394L23.6717 25.9947L17.5 21.5106L11.3283 25.9947L13.6857 18.7394L7.51391 14.2553H15.1426L17.5 7Z'
					fill='white' />
			</svg>
		)
	};

	return allTypes[medalType];
};

const DBLeaderboardHeading = (props) => {
	const tableHeaders = (props && props.data && props.data.headings && props.data.headings.desktop || ['Position', 'Team', 'Driver', 'Pts', 'Time']).map((item, index) => (
		<div key={index} className='table-heading-item'>{item}</div>));

	return (
		<>
			<div className='leaderboard-heading c-leaderboard'>
				<div className='leaderboard-heading-inner'>
					<h3>{props.data.heading}</h3>
					{props.data.sponsor &&
						<a target='_blank' href={props.data.sponsor.url} title={props.data.sponsor.title}>
							<img src={props.data.sponsor.logo.file.url} />
						</a>}
					<div className='rounded-corner'>
						<svg width='65' height='43' viewBox='0 0 65 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M0 43V0.5C33.3333 4.16667 90.3 11.5 51.5 11.5C12.7 11.5 1 35.1667 0 43Z'
								  fill='#003530' />
						</svg>
					</div>
				</div>

				<div className='leaderboard-table-heading'>
					{tableHeaders}
				</div>
			</div>
		</>
	);
};

const DBLeaderboardRows = (props) => {


	const getLastColumn = (rowItem) => {
		let columnData;
		let medalType;

		if (currentLeaderboardConfig && currentLeaderboardConfig.content) {
			columnData = currentLeaderboardConfig.content.column;

			switch (rowItem.position) {
				case 1:
					medalType = 'gold';
					break;
				case 2:
					medalType = 'silver';
					break;
				case 3:
					medalType = 'bronze';
					break;
				default:
					medalType = 'notype';
					break;
			}

			return rowItem[columnData] >= 0 ? (
				<span className='c-unskew'>{rowItem[columnData]}</span>
			) : (
				<span className={`c-unskew ${columnData}`}>
        {getMedal(medalType)}
      </span>
			);
		}

		// Return a default value or render a fallback component if currentLeaderboardConfig or currentLeaderboardConfig.content is falsy
		return <span>-</span>;
	};

	const handleRowClick = function(link) {
		props.history.push(link);
	};

	const tableRows = props.data && props.data.items ? props.data.items.map((item, index) => {
		if (item) {
			const tdClassName = `c-highlight c-highlight-col-${index + 1}`;
			return (
				<tr className='c-table__link' key={tdClassName} onClick={() => handleRowClick(item.teamUrl)}>
					<td className={tdClassName}>
						<span className='c-unskew'>{item.position}</span>
					</td>
					<td>
						{item.countryFlag ?
							<img src={item.countryFlag.file.url} className='c-leaderboard__flag c-unskew__img' /> :
							<img src={item.teamFlag.file.url} className='c-leaderboard__flag c-unskew__img' />}
					</td>
					<td>
						<div className='c-unskew'>
							<span>{item.teamName}</span>
							<img src={item.teamLogo.file.url} className='c-leaderboard__flag c-unskew__img' />
						</div>
					</td>
					<td>
						<div className='c-table__heading__wrapper'>
							<span className='c-table__heading__full-name c-unskew'>{item.helmFullName || ''}</span>
							<span className='c-table__heading__short-name c-unskew'>{item.helmShortName || ''}</span>
						</div>
					</td>
					<td>
						{getLastColumn(item)}
					</td>
				</tr>
			);
		}
		return null; // Return null if item is falsy
	}) : null; // Return null if props.data.items is falsy

	return (
		<>
			<div className={`leaderboard-rows c-leaderboard`}>
				<div className='c-leaderboard__inner'>
					<table>
						<tbody>
						{tableRows}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

const DBLeaderboard = (props) => {
	currentLeaderboardConfig = defineContent(props);

	const lastColumnType =
		currentLeaderboardConfig &&
		currentLeaderboardConfig.content &&
		currentLeaderboardConfig.content.column
			? currentLeaderboardConfig.content.column
			: '';

	return (<div className={`DBLeaderboard last-column-type-${lastColumnType}`}>
		<DBLeaderboardHeading {...props} />
		<DBLeaderboardRows {...props} />
	</div>);
};

export default DBLeaderboard;
