import fetch from 'isomorphic-fetch';
import { MICROSITE_TYPE } from '../App';
import { getContentAPIURL } from './../lib/content-api-utils';
import trailingSlash from './../lib/trailing-slash-it';

const getInitialData = async ({ match, options }) => {
	const MICROSITE = "mediahub-microsite";
	try {
		let page = MICROSITE_TYPE;
		let url = getContentAPIURL();

		if (match.url === '/' || match.url === '/index.html') {
			url += `page/${page}/`;
		} else {
			const path = match.url.startsWith('/') ? match.url : `/${match.url}`;
			const hasMicrosite = path.includes(MICROSITE_TYPE) || path.includes('/news/') || path.includes('/teams/') || path.includes('/races/');
			page = hasMicrosite ? path : `/${page}${path}`;
			url += `page${trailingSlash(page)}`;
		}

		url += "?cacheContext=" + MICROSITE;
		let headers = {};
		if (options && options.headers) {
			headers = { ...options.headers };
		}
		const response = await fetch(url, {
			headers: new Headers(headers),
		});

		if (response.ok === false) {
			let errObj;

			switch (response.status) {
				case 404:
					errObj = {
						errorType: 404,
						errorMessage: `404 Content API: Page '${page}' was not found`,
						error: response
					}
					break;

				default:
					errObj = {
						errorType: response.status,
						errorMessage: response.statusText,
						error: response
					}
					break;
			}

			return errObj;
		}

		const data = await response.json();

		return data;
	} catch (error) {
		console.log(error); // eslint-disable-line no-console

		return {
			errorType: 500,
			error: error
		};
	}
};

export default getInitialData;
