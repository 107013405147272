import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import eyeIcon from '../../../../public/images/eye-icon.svg'
import { Tooltip } from 'antd';

export default function ApprovedQuestion(props) {
	const {
		id,
		approvedQuestionId,
		askedQuestionId,
		askQuestion,
		contentfulData,
		permanentRole,
		pendingQuestions,
		getApprovedQuestions,
		approvedQuestions
	} = props

	useEffect(() => {
		if(permanentRole !== 'Journalist') {
			getApprovedQuestions()
		}
	}, [id, approvedQuestionId, askedQuestionId, permanentRole]);

	const relevantQuestions = permanentRole !== 'Journalist' ? approvedQuestions : pendingQuestions;

	return (
		<div className="press-conference-questions">
			{relevantQuestions.length > 0 &&
				[...relevantQuestions].reverse().map(info => {
					return (
						<div key={info._id} className="questions-asked clearfix">
							<div className='questions-asked__direct-to'>
								<p>Question directed to: {info.participant}</p>
							</div>
							<div className='questions-asked__question clearfix'>
								<p className="pull-left">
									{info.question}
								</p>
								<div className="btn-question-asked pull-right">
									{permanentRole !== 'Journalist' ?
										<button
											onClick={() => {
												askQuestion(info._id);
												getApprovedQuestions();
											}}
											className="pending-question pending-question__ask">
											{contentfulData.asked.toUpperCase()}
										</button> :
										<span className={`pending-question pending-question__${info.className}`}>
											{info.statusText}
											{info.status === '3' && <span className='pending-question__info-icon'>
												<Tooltip title={info.questionfeedback} color='#537577'>
													<img src={eyeIcon} alt='info'/>
												</Tooltip>

											</span>}
										</span>
									}
								</div>
							</div>
						</div>
					);
				})}
		</div>
	);
}

ApprovedQuestion.propTypes = {
	contentfulData: PropTypes.object,
	id: PropTypes.string,
	approvedQuestionId: PropTypes.string,
	askedQuestionId: PropTypes.string,
	askQuestion: PropTypes.func,
	permanentRole: PropTypes.string,
	pendingQuestions: PropTypes.array,
	getApprovedQuestions: PropTypes.func,
	approvedQuestions: PropTypes.array
};
