import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import defaultProfileIcon from '../../../../public/images/SailGP_White_S.png';
import arrowDown from '../../../../public/images/triangle_down.svg'
import { rejectQuestionApiCall } from './api/press-api';
import PropTypes from 'prop-types';

export const LiveFeedCard = ({info, contentfulData, permanentRole, approveQuestion}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)

	const handleDropdownClick = () => {
		setIsDropdownOpen(prevOpen => !prevOpen)
	}

	const handleSelect = async (e, id) => {
		setIsDropdownOpen(false)
		const payLoadBody = {
			status: '3',
			questionfeedback: e.target.innerText
		};
		try {
			const rejectQuestionApi = await rejectQuestionApiCall(id, payLoadBody);

			if (rejectQuestionApi.status === 200) {
				toast.success(contentfulData.rejectQuestionSuccess)
			} else {
				toast.error(contentfulData.rejectQuestionError)
			}
		} catch (error) {
			toast.error(contentfulData.rejectQuestionError)
		}


	}

	return (
		<div className="questions-list" key={info._id}>

			<div className="journalist-profile">
				<img
					src={
						(info.User[0] && info.User[0].profileImage) ? info.User[0].profileImage : defaultProfileIcon}
					alt="journalist"
				/>
			</div>
			<div className="question-section">
				<div className="question-created clearfix">
					<span>{info.User[0] ? info.User[0].firstname + ' ' + info.User[0].lastname : 'XXX'}</span>
					<label>{moment(info.createdOn).fromNow()}</label>
				</div>
				<div className="question-content">
					<br></br>
					<div className='question-content__direct-to'>
						<p>Directed to: {info.participant}</p>
					</div>

					<p>{info.question}</p>
					{(permanentRole === 'PressOffice' || permanentRole === 'Admin') && permanentRole !== 'Journalist' && (
						<div className="accept-reject">
							<div className='accept-reject__buttons'>
								<button className="accept-reject__button accept-button" onClick={() => approveQuestion(info._id)}>
									Approve
								</button>
							</div>

							<div className='accept-reject__buttons reject-dropdown'>
								<button className="accept-reject__button reject-button"
									onClick={() => handleDropdownClick()}>
										Reject
									<img className='reject-dropdown__arrow' src={arrowDown} alt=''/>
								</button>
								<div className={`reject-dropdown-content ${isDropdownOpen ? 'reject-dropdown-content__show' : ''}`}>
									{contentfulData.rejectCommentReason.map((reason) =>
										<button key={reason} className='reject-dropdown-content__reason' onClick={(e) => handleSelect(e, info._id)}>
											{reason}
										</button>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

LiveFeedCard.propTypes = {
	contentfulData: PropTypes.object,
	info: PropTypes.object,
	permanentRole: PropTypes.string,
	approveQuestion: PropTypes.func
}
