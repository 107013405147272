import { fetchGetData, fetchPostData } from '../../../utils/fetchApi';
import { fetchPageData } from '../../../utils/fetchApiData';

const getAllEventsData = (permanentRole, conferenceId) => {
	return fetchPageData(`event?limit=&skip=`)
		.then(apiURL => {
			const allEventData = fetchGetData(apiURL, 'GET', true, permanentRole);
			return allEventData
		})
		.then(response => response.json())
		.then(resData => {
			if (resData.status === 1) {
				const { baseUrl } = resData.data
				const [pressConferenceIdData] = resData.data.data.filter(e => e._id === conferenceId);
				if(pressConferenceIdData) {
					pressConferenceIdData.backgroundImageUrl = baseUrl + pressConferenceIdData.photoUrl
				}
				return pressConferenceIdData;
			}
			else if(resData.message == "No token provided."){
				return {
					status: "logout"
				}
			}
		})
		.catch((err) => {
			console.error(err)
		});
}

// Get all question in Live Feed section
const getAllQuestionsDataApi = (id, permanentRole) => {
	return fetchPageData(`question/live_feed/${id}`)
		.then(apiURL => {
			const allQuestionList = fetchGetData(apiURL, 'GET', true, permanentRole);
			return allQuestionList
				.then(response => response.json())
				.then(resData => {
					if (resData.status === 1) {
						return resData.data && resData.data.data
					}else if(resData.message == "No token provided."){
						return {
							status: "logout"
						}
					}
				})
				.catch(error => { });
		});
}

//Submit new Question api call
const submitQuestionsApi = async(payLoadBody) => {
	const getCreateQuestionAPIURL = await fetchPageData('question/create');
	const createQuestion = await fetchPostData(getCreateQuestionAPIURL, 'POST', payLoadBody, true);
	if(createQuestion.status === 200) {
		return createQuestion
	}
}
// Approve and Asked question api call
const approveAndAskQuestionApi = async(id, payLoadBody) => {
	const getApproveQuestionAPIURL = await fetchPageData(`question/${id}`);
	const apiResponse = await fetchPostData(getApproveQuestionAPIURL, 'PATCH', payLoadBody, true);
	if(apiResponse.status === 200) {
		return apiResponse
	}
}

//approvedQuestionList api call in <ApprovedQuestion />
const approveQuestionList = (id) => {
	return fetchPageData(`question/interactions/${id}/1`)
		.then(apiURL => {
			const approvedQuestionList = fetchGetData(apiURL, 'GET', true);
			return approvedQuestionList
				.then(response => response.json())
				.then(resObj => {
					if (resObj.status === 1) {
						return resObj.data.data
					}
				})
				.catch(error => {
					console.error(error)
				});
		});
}

// Interaction section api call from <InteractionSection /> components
const getInteractionApi = (id, permanentRole) => {
	return fetchPageData(`question/interactions/${id}/2`)
		.then(apiURL => {
			const dataList = fetchGetData(apiURL, 'GET', true, permanentRole);
			return dataList.then(response => response.json())
				.then(resObj => {
					if (resObj.status === 1) {
						return resObj.data.data
					}
				})
				.catch(error => {});
		});
}

// Live Feed Api
const liveFeedApiCall = (id, permanentRole) => {
	return fetchPageData(`question/live_feed/${id}`)
		.then(apiURL => {
			const submittedQuestion = fetchGetData(apiURL, 'GET', true , permanentRole);
			return submittedQuestion
				.then(response => response.json())
				.then(resObj => {
					if (resObj.status === 1) {
						return resObj.data
					}
				})
				.catch(error => {});
		});
}

//Reject question Api call
const rejectQuestionApiCall = async (questionId, payLoadBody) => {
	const getRejectQuestionAPIURL = await fetchPageData(`question/${questionId}`);
	const apiResponse = await fetchPostData(getRejectQuestionAPIURL, 'PATCH', payLoadBody, true);
	if(apiResponse.status === 200) {
		return apiResponse
	}
}


export { getAllEventsData, getAllQuestionsDataApi, submitQuestionsApi,
	approveAndAskQuestionApi, approveQuestionList, getInteractionApi,
	liveFeedApiCall, rejectQuestionApiCall
}
