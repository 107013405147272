import React, { useContext } from 'react';
import UserContext from '../../../context/UserContext';

export const getReplaceMap = () => {
	const {user} = useContext(UserContext);

	return {
		'{{nearAccountID}}': user.nearAccountID || '',
		'{{firstName}}': user.firstName || '',
		'{{lastName}}': user.lastName
	};
};
