import React from 'react';
import {ContactBlock} from './Contact/ContactBlock';
import PropTypes from 'prop-types';

export default function ContactsListing({data}) {
	const {contacts, heading} = data;

	return (
		<section className="c-team-listing">
			<h2 className="c-team-listing__heading">
				{heading && <span>{heading}</span>}
			</h2>
			<div className="c-team-listing__teams-container">
				{
					contacts.map(contact => (
						<div
							className="c-team-listing__team-block-wrapper"
							key={contact.contentfulId}
						>{contact.CountryLogo}
							<ContactBlock
								code={contact.code}
								name={contact.contactName}
								logo={contact.countryFlag}
								url={contact.url}
								image={contact.countryLogo}
								jobTitle={contact.jobTitle}
								email={contact.email}
								phoneNumber={contact.phoneNumber}
							/>
						</div>
					))
				}
			</div>
		</section>
	);
}

ContactsListing.propTypes = {
	data: PropTypes.object.isRequired,
};
