import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const Grid = forwardRef(
	function Grid(props, ref) {
		const {
			align,
			children,
			className,
			fixedColumnWidth,
			noVerticalPadding,
			noHorizontalPadding,
			tag: Tag,
			...otherProps
		} = props;

		const classes = classnames('l-grid', className, {
			[`l-grid--align-${align}`]: !!align,
			'l-grid--fixed-column-width': fixedColumnWidth,
			'l-grid--no-vertical-padding': noVerticalPadding,
			'l-grid--no-horizontal-padding': noHorizontalPadding,
		});

		return (
			<Tag className={classes} {...otherProps} ref={ref}>
				{children}
			</Tag>
		);
	}
);

Grid.propTypes = {
	align: PropTypes.oneOf(['left', 'right']),
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	fixedColumnWidth: PropTypes.bool,
	noVerticalPadding: PropTypes.bool,
	noHorizontalPadding: PropTypes.bool,
	tag: PropTypes.string,
};

Grid.defaultProps = {
	align: undefined,
	className: '',
	fixedColumnWidth: false,
	noVerticalPadding: false,
	noHorizontalPadding: false,
	tag: 'div',
};

export default Grid;
