import React, {useContext, useEffect, useState, useRef, memo} from 'react';
import {withRouter} from 'react-router-dom';

import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import Recaptcha from 'react-google-invisible-recaptcha';
import './Form.scss';
import {FormProvider, useForm} from 'react-hook-form';
import UserContext from '../../context/UserContext';
import {fetchContent} from '../../utils/fetchPageContent';
import 'react-toastify/dist/ReactToastify.css';
import {MyAccountFormInitialization} from '../MyAccount/MyAccount';
import {MyAccountDeleteUser} from '../MyAccount/DeleteUser';
import {
	deleteEventsForm,
	PressConferenceCreateNewEventsForm,
	PressConferenceEditEventsForm,
	PressConferenceEditEventsPreFilledForm
} from '../DevModule/PressConferenceAdmin/api/press-conference-api';
import {getReCaptcha} from '../../lib/content-api-utils';
import MediaHubCTA from '../MediaHubCTA/MediaHubCTA';
import EditControl from './WebForm/EditControl';
import DynamicControl from './WebForm/DynamicControl';
import InFormParagraph from './WebForm/InFormParagraph';
import {PhotoBoatSignup, PhotoBoatSignupFormInitialize} from './api/PhotoBoatSignup';
import {registerUsers} from './api/Register';
import { changePassword } from './api/changePassword';
import { verifyToken } from './api/verifyToken';

const UserPermissionLevels = {
	Journalist: 3,
	PressOffice: 2,
	Admin: 1
}

const apiResponseErrorEnum = {
	validationError : 'validation_error',
	invalidGrant: 'invalid_grant',
	emailAlreadyRegistered: 'emailAlreadyRegistered'
}

function FormGroup(props) {

	const paramData = props.data;
	const formMethods = useForm({mode: 'onBlur'});
	const formFields = paramData.formFields;
	const {closeEditPopUp, preFillValues} = props


	const {
		register,
		handleSubmit,
		formState: {isSubmitting, errors},
		watch,
		setValue,
		getValues,
		reset,
	} = formMethods;

	const {logIn, registerUser, updateProfile, user, resetPassword, logOut} = useContext(UserContext);

	const [countryList, setCountryList] = useState(false);
	const [userData, setUserData] = useState({});
	const [radioUpdateFlag, setRadioUpdateFlag] = useState(false);

	//get country data
	if (paramData.formSubmitAction == 'updateProfile' || paramData.formSubmitAction == 'registerUser') {
		useEffect(() => {
			(async () => {
				const countryListResult = await fetchContent('country');
				setCountryList(countryListResult);
			})()
		}, [])
	}

	useEffect(() => {
		if(paramData.formSubmitAction === 'updateProfile') {
			setUserData(user)
		}
	}, [])


	useEffect( () => {
		(async() => {
			if (paramData.formSubmitAction === 'logIn' && paramData.verifyUrl) {
				const search = window.location.search;
				const params = new URLSearchParams(search);
				const token = params.get('token');
				if(token) {
					toast.loading('Please wait')
					props.history.replace({token: ''});
					const { status } = await verifyToken(token)
					if(status === 200) {
						toast.dismiss()
						toast.success('Email verified successfully')
					} else {
						toast.dismiss()
						toast.error('Email verification unsuccessful')
					}
				}
			}
		})()
	}, [])

	const recaptchaRef = useRef(null);
	const sitekey = getReCaptcha();
	useEffect(() => {
		if (paramData.formSubmitAction == 'photoboatSignUp') {
			PhotoBoatSignupFormInitialize(setValue, paramData);
		}
		if (paramData.formSubmitAction == 'editEvent') {
			PressConferenceEditEventsPreFilledForm(setValue, preFillValues, radioUpdateFlag, setRadioUpdateFlag);
		}
		if (paramData.formSubmitAction == 'updateProfile') {
			MyAccountFormInitialization(setValue, paramData);
		}
	}, [user, preFillValues]);


	const onResolved = async value => {
		const formValues = JSON.parse(typeof window !== 'undefined' && window.sessionStorage ? sessionStorage.getItem('formValues') : null);
		if (formValues && Object.keys(formValues).length > 0) {
			const registerResponse = await registerUsers(formValues, value, paramData.successUrl3);
			if (registerResponse.status === 'success') {
				sessionStorage.setItem('registrationSuccess', true);
				toast.dismiss()
				reset();
				props.history.push(paramData.successUrl);
			} else {
				toast.dismiss()
				sessionStorage.setItem('registrationSuccess', false)

				if(registerResponse.errorId === apiResponseErrorEnum.emailAlreadyRegistered && registerResponse.errorDescription) {
					toast.error(registerResponse.errorDescription, {position: toast.POSITION.TOP_CENTER});
				} else {
					toast.error(paramData.errorMessage, {position: toast.POSITION.TOP_CENTER});
				}
			}
		}

	};

	function toasterMsg(updateResp) {
		if (updateResp.status === 200 || updateResp.status === 'success') {
			reset();
			toast.success(paramData.successMessage, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true
			});
			setTimeout(() => {
				props.history.push('/home');
			}, 2000);
		} else {
			toast.error(paramData.errorMessage, {position: toast.POSITION.TOP_CENTER});
		}
	}

	const onSubmit = async (data) => {
		const toastId = toast.loading('Please wait...');
		sessionStorage.setItem('formValues', JSON.stringify(data));
		switch (paramData.formSubmitAction) {
			case logIn.name:
				const response = await logIn(data, reset);

				if (response && response.status === 'success') {
					toast.dismiss()
					props.history.push('/home');
				} else if (response.status === 'failed' && response.error && response.error === apiResponseErrorEnum.invalidGrant) {
					toast.dismiss(toastId);
					toast.error('Invalid email or password', {
						position: toast.POSITION.TOP_CENTER
					});
				} else if(response.status === 'failed' && response.error && response.error === apiResponseErrorEnum.validationError) {
					toast.dismiss(toastId);
					toast.error('User not yet approved by admin', {
						position: toast.POSITION.TOP_CENTER
					});
				} else {
					toast.dismiss(toastId);
					toast.error('There has been a problem logging in', {
						position: toast.POSITION.TOP_CENTER
					});
				}
				break;
			case registerUser.name:
				recaptchaRef.current.execute();
				break;
			case updateProfile.name:
				const updateProfStatus = await updateProfile(data);
				toast.dismiss(toastId);
				toasterMsg(updateProfStatus);
				break;
			case 'photoboatSignUp':
				const PhotoBoatStatus = await PhotoBoatSignup(data, reset, paramData, setValue);
				if (Object.keys(PhotoBoatStatus).length !== 0 && PhotoBoatStatus.status === 1) {
					toast.success(paramData.successMessage, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000,
						hideProgressBar: true
					});
					toast.dismiss(toastId);
				} else {
					toast.error(paramData.errorMessage, {position: toast.POSITION.TOP_CENTER});
					toast.dismiss(toastId);
				}
				break;
			case 'createEvent':
				await PressConferenceCreateNewEventsForm(data, reset, paramData, setValue);
				toast.dismiss(toastId)
				toast.success(paramData.successMessage, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1000,
					hideProgressBar: true});
				closeEditPopUp(false)
				break;
			case 'editEvent':
				await PressConferenceEditEventsForm(data, paramData, preFillValues._id, getValues);
				toast.dismiss(toastId);
				await closeEditPopUp(false);
				break;
			case 'forgotPassword':
				await resetPassword(data.emailAddress, reset, paramData.successUrl3);
				break;
			case 'resetPassword':
				const { status } = await changePassword(paramData, data);
				if(status === 1) {
					toast.success('Password changed successfully');
					location.assign('/user/login');

				} else {
					toast.error('Error - please try again');
					reset();
				}
				toast.dismiss(toastId);
				break;
			default:
				console.warn('method not found');
				break;
		}
	};

	const secondaryHandler = async () => {
		switch (paramData.formSecondaryAction) {
			case 'deleteAccount':
				const updateProfStatus = await MyAccountDeleteUser(reset, getValues);
				reset();
				toast.warn(updateProfStatus, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 1600,
					hideProgressBar: true
				});
				setTimeout(() => {
					logOut()
				}, 2000);

				break;
			case 'deleteEvents':
				await deleteEventsForm(preFillValues._id);
				await closeEditPopUp(false);
				break;
			default:
				console.warn('method not found');
				break;
		}
	};

	function roleChange(e) {
		if ((paramData.formSubmitAction == 'updateProfile')) {
			sessionStorage.setItem('userRole', e.target.value);
			toast.success(`Role temporarily updated to ${e.target.value}`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true
			});
		}
	}

	const getControlComponent = (field) => {

		switch (paramData.formSubmitAction) {
			case 'updateProfile':
				if(Object.keys(userData).length > 0) {
					return <EditControl fieldData={field} fieldValue={userData[field.apiField]}
						userData={userData} setUserData={setUserData} countryData={countryList}
						roleChange={roleChange} UserPermissionLevels={UserPermissionLevels} />;
				}
				break;
			default:
				return <DynamicControl field={field} countryData={countryList} radioUpdateFlag={radioUpdateFlag} setRadioUpdateFlag={setRadioUpdateFlag} errors={errors}/>;
		}
	};

	const formLabels = (field) => {
		return <div className={'inputField flex flex-col '
		+ (field.required ? 'required ' : '')
		+ (field.extraMarginBottom ? 'mb-large ' : '')
		+ (field.extraMarginTop ? 'mt-large ' : '')
		+ (field.appExtraMarginTop ? 'app-mt-large ' : '')
		+ (field.appExtraMarginBottom ? 'app-mb-large ' : '')
		+ (field.appExtraHugeMarginBottom ? 'app-mb-huge ' : '')
		+ (errors[field.apiField] ? 'error ' : '')
		+ (field.appClassNames
			&& field.appClassNames.length > 0
			&& field.appClassNames.join(' '))}
					data-field-type={field.type}
					key={field.contentfulId}>
			{
				field.label &&
				<div>
					<label htmlFor={field.apiName}
						   className="text-sm">{
						field.label}</label>
				</div>
			}


			{
				field.label && field.miniContent &&
				<div>
					<p className="text-mini">{field.miniContent}</p>
				</div>

			}
			{
				field.label && field.label2 &&
				<div>
					<p className="text-label2">{field.label2}</p>
				</div>

			}
			<div>
				{getControlComponent(field)}

				{errors[field.apiField] && errors[field.apiField].type === 'required' && (
					<p className="text-red-500">{field.errorMessage}</p>
				)}
				{errors[field.apiField] && (errors[field.apiField].type === 'pattern'
					|| errors[field.apiField].type === 'validate') && (
					<p className="text-red-500">{field.validationErrorMessage}</p>
				)}
			</div>
		</div>
	}

	const isAppView = false


	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)} style={{height: 'auto'}}
				  className={`${paramData.formSubmitAction}${isAppView ? '__app' : ''}`}>
				<FormProvider {...formMethods} >
					<div className="flex flex-col md:flex-row gap-7 w-full py-7 px-4 shadow-md rounded-sm">
						{formFields.map((field) => (field.type === 'paragraph')
							? (
								<InFormParagraph fieldData={field} key={field.contentfulId} isAppView={isAppView}/>
							)
							: (<div key={field.contentfulId}>
									{
										(field.type === 'role')
											? <>{userData.userPermissionLevel < 3 && <>{field.childFields &&  field.childFields.length > 0 ? field.childFields.map(childField => formLabels(childField)) : formLabels(field)}</>}</>
											: <>{field.childFields &&  field.childFields.length > 0 ? field.childFields.map(childField => formLabels(childField)) : formLabels(field)}</>

									}
								</div>
							))}
						{paramData.includeRecaptcha && (
							<div className="recaptcha">
								<Recaptcha ref={recaptchaRef} sitekey={sitekey} onResolved={onResolved} badge="inline"/>
							</div>
						)}

						<MediaHubCTA
							type="submit" disabled={isSubmitting || (Object.keys(errors).length > 0)}
							label={paramData.submitLabel || (isSubmitting ? 'Submitting' : 'Submit')}
						/>
						{paramData.formSecondaryAction && <MediaHubCTA onClick={secondaryHandler} label={paramData.formSecondaryLabel} type='button'/>}
					</div>
				</FormProvider>

			</form>
		</div>
	);
}

FormGroup.propTypes = {
	history: PropTypes.object,
	data: PropTypes.object,
	closeEditPopUp: PropTypes.func,
	preFillValues: PropTypes.object
};

export default withRouter(memo(FormGroup));
