import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RaceSchedule from '../../RaceSchedule';
import Analytics from './../../../lib/analytics';

class RaceScheduleItemContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			raceDays: props.raceDays,
		};

		this.handleClick = this.handleClick.bind(this);
		this.localiseDates = this.localiseDates.bind(this);
	}

	componentDidMount() {
		this.localiseDates();
	}

	localiseDates() {
		const dateOptions = {
			weekday: 'long',
			month: 'long',
			day: 'numeric',
		};

		const timeOptions = {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		};

		const raceDays = this.state.raceDays.map(day => {
			const dayDate = new Date(day.startDateTime);
			day._dateString = dayDate.toLocaleString(this.props.locale, dateOptions);

			day.races = day.races.map(race => {
				const raceDate = new Date(race.startDateTime);
				const time = raceDate.toLocaleString(this.props.locale, timeOptions);

				return { _timeString: time, ...race };
			});

			return day;
		});

		this.setState({ raceDays });
	}

	handleClick() {
		Analytics.event({
			'eventName': 'CTA Interaction',
			'category': 'CTA Interaction',
			'action': 'Race Report Link',
			'label': this.props.title,
		});
	}

	render() {
		return (
			<RaceSchedule
				{...this.props}
				raceDays={this.state.raceDays}
				action={this.handleClick}
			/>
		);
	}
}

RaceScheduleItemContainer.propTypes = {
	title: PropTypes.string,
	raceDays: PropTypes.array,
	locale: PropTypes.string,
};

export default RaceScheduleItemContainer;
