import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Select } from 'antd';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';

export default function UserRequestFilter(props) {
	const { sortbyOptions, showUserRequest, handleChange, Option, csvData,
		csvPendingData } = props;

	return (
		<Row className="user-requests-role-sort-options">
			<Col>
				<label>Download</label>
				<span className="user-requests-download-icon">
					<CSVLink data={showUserRequest === 'existingusers' ? csvData && csvData : csvPendingData && csvPendingData}>
						<DownloadOutlined />
					</CSVLink>
				</span>
			</Col>

			<Col className='user-requests-sort-by-option'>
				<label>Sort By</label>
				<Select allowClear defaultValue="Newest to Oldest" id="Newest to Oldest" onChange={handleChange}>
					{sortbyOptions.map(roleSort => {
						if ((roleSort.label !== 'Approved users' && showUserRequest === 'pendingusers') || showUserRequest === 'existingusers') {
							return (
								<Option key={roleSort.id} value={roleSort.label}>
									{roleSort.label}
								</Option>
							);
						} else {
							return null;
						}
					})}
				</Select>
			</Col>
		</Row>
	);
}

UserRequestFilter.propTypes = {
	sortbyOptions: PropTypes.array,
	tablePendingDataSource: PropTypes.object,
	showUserRequest: PropTypes.string,
	handleChange: PropTypes.func,
	Option: PropTypes.func,
	csvData: PropTypes.array
};
