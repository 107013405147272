import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {withNavigation} from '.';
import PageContext from '../../context/PageContext';
import {StickyHeader} from '@sailgp/sailgp-shared-components';
import RolexCountdown from '../../components/Rolex/RolexCountdown';
import RolexClock from '../../components/Rolex/RolexClock';
import SectionNavContainer from './SectionNavContainer';

const StickyHeaderContainer = props => {
	const navigatePrimaryCta = (path, openInNewWindow) => {
		openInNewWindow ? window.open(path, '_blank') : location.assign(path)
	}
	return (
		<PageContext.Consumer>
			{({toggleSideDrawerOpen, activeLanguage}) => (
				<StickyHeader
					toggleSideDrawerOpen={toggleSideDrawerOpen}
					activeLanguage={activeLanguage}
					{...props}
					sectionNav={<SectionNavContainer data={props.sectionNavigation}/>}
					RolexCountdown={<RolexCountdown/>}
					RolexClock={<RolexClock timeZoneLabel='Christchurch'/>}
					logoLink={'/home'}
					navigatePrimaryCta={navigatePrimaryCta}
				>
				</StickyHeader>
			)}
		</PageContext.Consumer>
	);
};

StickyHeaderContainer.propTypes = {
	children: PropTypes.any,
	navItems: PropTypes.array,
	nextRace: PropTypes.object,
	sectionNavigation: PropTypes.object,
	selectedItem: PropTypes.any,
	getSecondaryNavItems: PropTypes.func,
	getSelectedNavItems: PropTypes.func,
	revealSecondaryNav: PropTypes.any,
	onSelectItemHandler: PropTypes.func,
	data: PropTypes.object,
};

StickyHeaderContainer.defaultProps = {
	data: {},
};

export default memo(withNavigation(StickyHeaderContainer));
