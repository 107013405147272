import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '../Grid';
import './HowToWatchFilter.scss';

const HowToWatchFilter = ({
	filters,
	activeFilter,
	onClickFilter,
	open,
	onToggle,
}) => (
	<Grid noVerticalPadding className="c-how-to-watch__filters">
		<button type="button" className="c-how-to-watch__open-button" onClick={onToggle}>{activeFilter}</button>
		<ul className={`c-how-to-watch__filter ${open ? 'c-how-to-watch__filter--open' : ''}`}>
			{
				filters.map(f =>
					<li key={f} className={`c-how-to-watch__filter-item ${activeFilter === f ? 'c-how-to-watch__filter-item--active' : ''}`}>
						<button onClick={() => onClickFilter(f)}>{f}</button>
					</li>
				)
			}
		</ul>
	</Grid>
);

HowToWatchFilter.propTypes = {
	filters: PropTypes.arrayOf(PropTypes.string),
	activeFilter: PropTypes.string,
	onClickFilter: PropTypes.func,
	open: PropTypes.bool,
	onToggle: PropTypes.func,
};

HowToWatchFilter.defaultProps = {
	filters: [],
	activeFilter: '',
	onClickFilter: () => {},
	open: false,
	onToggle: () => {},
};

export default HowToWatchFilter;
