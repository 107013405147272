import fetch from 'isomorphic-fetch';
import { getContentAPIURL } from './../../../lib/content-api-utils';
import Logger from './../../../lib/logger';

const logger = Logger.get('LiveFeed');
const apiURL = getContentAPIURL();

export default async function fetchLiveFeedAPI(url) {
	try {
		const res = await fetch(`${apiURL}page/${url}`);
		const json = await res.json();

		return json;
	} catch (error) {
		logger.error(error);

		return {};
	}
}
