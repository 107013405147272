import React from 'react';
import PropTypes from 'prop-types';
import { ConfigurableGrid } from '@sailgp/sailgp-shared-components';
import pageComponentsList from '../ComponentsList';

export function ConfigurableGridContainer(props) {

	return (
		<ConfigurableGrid
			validComponents={pageComponentsList.configurableGridComponents}
			data={props.data}
		/>
	);
}

ConfigurableGridContainer.propTypes = {
	data: PropTypes.object,
};

ConfigurableGridContainer.defaultProps = {
	data: {},
};


