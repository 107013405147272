import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, Cell } from '../Grid';
import Table from '.';

const TableContainer = ({ data }) => {
	const { title, subtitle, items } = data;

	return (
		<Grid>
			<Row>
				<Cell xlCols={2} lgCols={2} />
				<Cell xlCols={8} lgCols={8} mdCols={6} smCols={2}>
					<Table
						title={title}
						subtitle={subtitle}
						items={items}
						alt />
				</Cell>
			</Row>
		</Grid>
	);
};

TableContainer.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		items: PropTypes.array,
	}),
};

TableContainer.defaultProps = {
	data: {
		title: '',
		subtitle: '',
		items: [],
	},
};

export default TableContainer;
