import { fetchPostData } from '../../../utils/fetchApi';
import {getSsoAPIURL} from "../../../lib/content-api-utils";

export const changePassword = async (paramData, values) => {

	try {
		const search = window.location.search
		const params = new URLSearchParams(search)
		const token = params.get('token')
		const user_id = params.get('userName');

		const changePasswordData = { 
			UserName : user_id, 
			Token : token, 
			newPassword: values.newPassword 
		}

		const changePasswordURL = await getSsoAPIURL('user/reset-password')

		return fetchPostData(changePasswordURL, 'POST', changePasswordData, false)
			.then(res => res.json()).then(response => {
				if(response['response']['status'] == "success"){
					return {status : 1}
				}else{
					return {status : 0}
				}
			} )
			.catch(err => err)

	} catch (error) {
		console.log(error, 'errcatch');
		return error
	}
}
