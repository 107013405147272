import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { differenceBy, get } from 'lodash';
import { format, formatDistance } from 'date-fns';
import { fr, zhCN, ja } from 'date-fns/locale';
import {MemoizedExternalVideo as ExternalVideo, Button} from '@sailgp/sailgp-shared-components';
import { Instagram, Twitter } from '../ExternalEmbed';
import LiveFeedItem from './LiveFeedItem';
import fetchLiveFeedAPI from './api/get-data';
import './LiveFeed.scss';
import Logger from './../../lib/logger';

const logger = Logger.get('LiveFeed');

const dateOrder = (array) => array.sort((a, b) => new Date(b.updated) - new Date(a.updated));

class LiveFeed extends Component {
	static checkInterval(callback, delay) {
		const dateNow = Date.now;
		const requestAnimation = typeof window !== 'undefined' ? window.requestAnimationFrame : () => { };
		let start = dateNow();
		let stop;
		const intervalFunc = () => {
			if (dateNow() - start >= delay) {
				start += delay;
				callback();
			}

			if (!stop) {
				requestAnimation(intervalFunc);
			}
		};

		requestAnimation(intervalFunc);

		return {
			clear: () => {
				stop = 1;
			}
		};
	}

	static convertTimeStampToTime(dateTimeStamp) {
		return format(new Date(dateTimeStamp), 'HH:mm');
	}

	static handleEmbed(id, type) {
		switch (type) {
			case 'Instagram':
				return <Instagram id={id} />;
			case 'YouTube':
				return <ExternalVideo data={{ videoId: id, videoProvider: type }} disableConstraint />;
			case 'Twitter':
				return <Twitter id={id} />;
			default:
				return null;
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			queuedItems: [],
			feed: props.liveFeed ? dateOrder(props.liveFeed) : [],
			summary: props.summary,
			maxItems: 20,
			itemsPerPage: props.itemsPerPage || 2,
			totalItemsVisible: props.itemsPerPage || 2
		};

		this.updateFrequency = 10000; // 10 seconds
		this.loadMore = this.loadMore.bind(this);
		this.getData = this.getData.bind(this);
		this.updateFeed = this.updateFeed.bind(this);
		this.convertTimeStampToElapsedTime = this.convertTimeStampToElapsedTime.bind(this);
	}

	componentDidMount() {
		LiveFeed.checkInterval(this.getData, this.updateFrequency);
	}

	convertTimeStampToElapsedTime(dateTimeStamp) {
		const { activeLanguage } = this.props;
		const lang = activeLanguage.code;

		const options = {
			addSuffix: true,
		};
		const locales = {
			fr,
			zh: zhCN,
			ja,
		};

		if (lang !== 'en') {
			options.locale = locales[lang];
		}

		return formatDistance(new Date(dateTimeStamp), new Date(), options);
	}

	updateFeed() {
		const { feed, queuedItems } = this.state;

		this.setState({
			feed: dateOrder(feed.concat(queuedItems)),
		}, () => {
			this.setState({
				queuedItems: [],
			});
		});
	}

	async getData() {
		const { contentfulId, websiteUrl } = this.props;
		const { feed } = this.state;
		const currentUrl = websiteUrl.replace('https://sailgp.com/', ''); // TODO, get relative url instead
		try {
			const data = await fetchLiveFeedAPI(currentUrl);
			const liveFeedCommponents = get(data, 'content.components', []);
			const liveFeedNode = liveFeedCommponents.filter(e => e.contentfulId === contentfulId)[0];
			const liveFeed = get(liveFeedNode, 'liveFeed.liveFeed', []);

			if (liveFeed.length > feed.length) {
				const newItems = differenceBy(liveFeed, feed, 'contentfulId');

				this.setState({
					queuedItems: dateOrder(newItems),
				});
			}
		} catch (error) {
			logger.error(error);
		}

	}

	loadMore(evt) {
		evt.preventDefault();

		let { totalItemsVisible } = this.state;
		const { itemsPerPage, maxItems } = this.state;

		totalItemsVisible += itemsPerPage;

		if (totalItemsVisible > maxItems) {
			totalItemsVisible = maxItems;
		}

		this.setState({
			totalItemsVisible,
		});
	}

	render() {
		const { heading, liveFeedLabel, summaryLabel, websiteUrl, newItemsLabel, newItemLabel, loadMoreLabel } = this.props;
		const { feed, summary, queuedItems, totalItemsVisible } = this.state;
		const pagedFeed = feed ? feed.slice(0, totalItemsVisible) : [];

		return (
			<Fragment>
				<section className="c-live-feed">
					<h2 className="c-live-feed__title">{heading}</h2>
					{
						queuedItems && queuedItems.length > 0 ?
							<Button
								variant="primary"
								onClick={this.updateFeed}>
								{
									queuedItems.length > 0 ?
										queuedItems.length !== 1 ? `${newItemsLabel.replace('{COUNT}', queuedItems.length)}` : newItemLabel
										: newItemLabel
								}
							</Button>
							: null
					}
					<div className="c-live-feed__grid">
						<div className="c-live-feed__column">
							<h3 className="c-live-feed__heading">{summaryLabel}</h3>
							{
								summary && summary.length > 0 ?
									<ul className="c-live-feed__summary-items">
										{
											summary.map(e =>
												<li key={e.contentfulId} className="c-live-feed__item">
													<LiveFeedItem
														{...e}
														convertTimeStampToElapsedTime={this.convertTimeStampToElapsedTime}
														convertTimeStampToTime={LiveFeed.convertTimeStampToTime}
														handleEmbed={LiveFeed.handleEmbed} />
												</li>
											)
										}
									</ul>
									:
									null
							}
						</div>
						<div className="c-live-feed__column">
							<h3 className="c-live-feed__heading">{liveFeedLabel}</h3>
							{
								pagedFeed && pagedFeed.length > 0 ?
									<ul className="c-live-feed__feed-items">
										{
											dateOrder(pagedFeed).map(e =>
												<li key={e.contentfulId} className="c-live-feed__item">
													<LiveFeedItem
														{...e}
														convertTimeStampToElapsedTime={this.convertTimeStampToElapsedTime}
														convertTimeStampToTime={LiveFeed.convertTimeStampToTime}
														handleEmbed={LiveFeed.handleEmbed}
														websiteUrl={websiteUrl} />
												</li>
											)
										}
									</ul>
									:
									null
							}
						</div>
					</div>
					{
						feed && feed.length > totalItemsVisible ?
							<Button variant="primary" onClick={this.loadMore}>{loadMoreLabel}</Button>
							: null
					}
				</section>
			</Fragment>
		);
	}
}

LiveFeed.propTypes = {
	data: PropTypes.object,
	heading: PropTypes.string,
	summary: PropTypes.array,
	liveFeed: PropTypes.array,
	itemsPerPage: PropTypes.number,
	contentfulId: PropTypes.string,
	websiteUrl: PropTypes.string,
	liveFeedLabel: PropTypes.string,
	summaryLabel: PropTypes.string,
	activeLanguage: PropTypes.object,
	newItemsLabel: PropTypes.string,
	newItemLabel: PropTypes.string,
};

LiveFeed.defaultProps = {
	data: {},
	heading: '',
	summary: [],
	liveFeed: [],
	itemsPerPage: 2,
	contentfulId: '',
	websiteUrl: '',
};

export default LiveFeed;
