import React from 'react';
import {withRouter} from 'react-router';
import {MemoizedLeaderboard as Leaderboard} from '@sailgp/sailgp-shared-components';
import DBLeaderboard from '../../components/DBLeaderboard';
import DBLeaderboardTabs from '../../components/DBLeaderboardTabs';
import '../../components/DBLeaderboard/DBLeaderboard.scss';

const LeaderboardContainer = (props) => {

	if (props.data.useDatabase) {
		if (props.data.leaderBoardType === 'All event leaderboards') {
			return (<DBLeaderboardTabs {...props} widget={null}/>)
		}
		return (<DBLeaderboard {...props} widget={null}/>)
	} else {
		return (<Leaderboard  {...props} widget={null}/>)
	}

}

export default withRouter(LeaderboardContainer);
