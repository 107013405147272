import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import classNames from 'classnames';
import './ContactBlock.scss';
import Img from '../../Img';
import {AngledDivider} from '../../Dividers';

export const ContactBlock = ({
								 className,
								 code,
								 name,
								 logo,
								 image,
								 angleFillColor,
								 shadow,
								 jobTitle,
								 email,
								 phoneNumber
							 }) => {

	const mailTo = (mail) => {
		return `mailto:${mail}`;

	};


	return (
		<div className={classNames(
			'c-contact-block',
			code ? `c-contact-block--${code}` : '',
			shadow ? 'c-contact-block--box-shadow' : '',
			className
		)}
		>
			<AngledDivider
				className={classNames(
					'c-contact-block__angle c-contact-block__angle--top',
					shadow ? 'hidden' : ''
				)}
				angleFillColor={angleFillColor || '#061a1b'}
			/>
			<section
				className="c-contact-block__image">
				{
					get(image, 'file.url') &&
					<Img
						src={get(image, 'file.url')}
						imgType={get(image, 'file.contentType')}
					/>
				}
			</section>
			<section
				className={classNames(
					'c-contact-block__info',
					shadow ? 'c-contact-block__skew_contents' : '',
				)}>
				<div>
					{logo &&
					<Img src={get(logo, 'file.url')} className="c-contact-block__logo" imgWidth={640} imgType="png"/>}
					<p
						className="c-contact-block__name">{name.toUpperCase()}</p>
					<p className="c-contact-block__item">{jobTitle}</p>
					<p className="c-contact-block__item">
						<a href={mailTo(email)}>
							{email}
						</a>
					</p>

					<p className="c-contact-block__item">{phoneNumber}</p>
				</div>
			</section>
			<AngledDivider
				className={classNames(
					'c-contact-block__angle c-contact-block__angle--bottom',
					shadow ? 'hidden' : ''
				)}
				angleFillColor={angleFillColor || '#061a1b'}
				anglePosition="top"
				angleDirection="left"
			/>
		</div>
	);
};

ContactBlock.propTypes = {
	className: PropTypes.string,
	code: PropTypes.string,
	name: PropTypes.string,
	jobTitle: PropTypes.string,
	email: PropTypes.string,
	phoneNumber: PropTypes.string,
	logo: PropTypes.object,
	image: PropTypes.object,
	viewcontactLabel: PropTypes.string,
	angleFillColor: PropTypes.string,
	shadow: PropTypes.bool,
};

ContactBlock.defaultProps = {
	code: '',
	name: '',
	url: '',
	fullName: '',
	logo: {},
	image: {},
	viewcontactLabel: '',
};


