import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';

class Twitter extends Component {
	constructor(props) {
		super(props);

		this.setRef = this.setRef.bind(this);
	}

	componentDidMount() {
		if (typeof window !== 'undefined' && window.twttr) {
			const { id } = this.props;

			window.twttr.widgets.createTweet(
				id,
				this.container,
			);
		}
	}

	setRef(ref, element) {
		this[ref] = element;
	}

	render() {
		return <div ref={e => this.setRef('container', e)} />;
	}
}

Twitter.propTypes = {
	id: PropTypes.string,
};

export default memo(Twitter);
