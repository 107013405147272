import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import Link from '../../Link';
import { getMediaResource } from '../../../lib/media';
import { event } from '../../../lib/analytics';
import Img from '../../Img';

import './TableItem.scss';
import { DateTime } from 'luxon';

const TableItem = ({
	rowLabel,
	title,
	subtitle,
	winner,
	image,
	time,
	linkUrl,
	linkLabel,
	wonByLabel,
	timeOverride,
	alt,
	startDateTime
}) => {
	const action = () => {
		event({
			'eventName': 'CTA Interaction',
			'category': 'CTA Interaction',
			'action': 'Race Report Link',
			'label': title,
		});
	};

	return (
		<>
			<div className="c-race-table-item__column c-race-table-item__column--1">
				{
					rowLabel ?
						<span className="c-race-table-item__row-label">{rowLabel}</span>
						:
						null
				}
			</div>
			<div className="c-race-table-item__column c-race-table-item__column--2">
				<span className="c-race-table-item__title">{winner && wonByLabel ? wonByLabel : title || ''}</span>
				<span className="c-race-table-item__subtitle">{winner && winner.code ? winner.code : subtitle || ''}</span>
				{
					(() => {
						if (winner && winner.code) {
							const imgSrc = winner.logo !== undefined && winner.logo.file !== undefined && winner.logo.file.url !== undefined ? winner.logo.file.url : `/images/${winner.code}.jpg`;
							return (
								<img src={getMediaResource(imgSrc)} alt={winner.code} className="c-race-table-item__flag" />
							);
						} else if (image) {
							return (
								<Img src={get(image, 'file.url')} alt={subtitle} className="c-race-table-item__flag" />
							);
						}
	
						return null;
					})()
				}
			</div>
			<div className="c-race-table-item__column c-race-table-item__column--time">
				{
					(() => {
						if (!linkUrl && (startDateTime || timeOverride)) {
							return (
								<span className="c-race-table-item__time">
									{
										timeOverride || DateTime.fromISO(startDateTime).toFormat('HH:mm')
									}
								</span>
							);
						}
	
						return null;
					})()
				}
			</div>
			<div className="c-race-table-item__column c-race-table-item__column--time">
				{
					(() => {
						if (linkUrl) {
							return (
								<Link
									to={linkUrl}
									className="c-race-table-item__cta"
									title={linkLabel}
									onClick={action}>
									{linkLabel || 'REPORT'}
								</Link>
							);
						} else if (timeOverride || startDateTime) {
							return (
								<span className="c-race-table-item__time">
									{
										timeOverride || DateTime.fromISO(startDateTime, { setZone: true }).toFormat('HH:mm')
									}
								</span>
							);
						}
	
						return null;
					})()
				}
			</div>
			<div className="c-race-table-item__separator" />
		</>
	);
};

TableItem.propTypes = {
	rowLabel: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	winner: PropTypes.string,
	image: PropTypes.object,
	time: PropTypes.string,
	linkUrl: PropTypes.string,
	linkLabel: PropTypes.string,
	wonByLabel: PropTypes.string,
	timeOverride: PropTypes.string,
	alt: PropTypes.bool,
	startDateTime: PropTypes.instanceOf(Date)
};

TableItem.defaultProps = {
	rowLabel: undefined,
	title: '',
	subtitle: '',
	winner: undefined,
	image: undefined,
	time: undefined,
	linkUrl: undefined,
	linkLabel: '',
	wonByLabel: undefined,
	timeOverride: undefined,
	alt: false,
};

export default TableItem;