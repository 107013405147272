import React from 'react';
import './DevModule.scss';
import {Grid} from '../Grid';
import PropTypes from 'prop-types';
import pageComponentsList from '../../containers/ComponentsList';
import PressConferenceAdmin from './PressConferenceAdmin';
import PhotoBoatAdmin from './PhotoBoatAdmin';
import UserRequestAdmin from './UserRequestAdmin';

const DevModuleEnum = {
	PressConferenceAdmin: 'pressConferenceAdmin',
	PhotoBoatAdmin: 'photoBoatAdmin',
	UserRequestAdmin: 'userRequestAdmin'
}

function DevModule(props) {
	return (
		<Grid className="l-grid has-editorial-spacing">
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.PressConferenceAdmin && <PressConferenceAdmin {...props}/>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.PhotoBoatAdmin && <PhotoBoatAdmin {...props}/>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.UserRequestAdmin && <UserRequestAdmin {...props}/>}
		</Grid>
	)
}

DevModule.propTypes = {
	data: PropTypes.object,
};

DevModule.defaultProps = {
	data: {},
};

export default DevModule
