import React from 'react';
import './MediaHubCTA.scss'
import PropTypes from 'prop-types';

const MediaHubCTA = ({ label, onClick, data, ...props}) => {
	let isDisabled;
	let type;
	if(props) {
		isDisabled = props.disabled;
		type = props.type;
	}
	const openInNewTab = () => {
		if (data) {
			if (data.openInSameTab) {
				return {
					href: data.url,
					rel: 'noopener noreferrer',
				}
			} else {
				return {
					href: data.url,
					target: '_blank',
					rel: 'noopener noreferrer',
				}
			}
		}

	}

	return (
		<button className={`c- c-mediahub-cta ${isDisabled ? 'c-mediahub-cta--disabled' : ''}`} onClick={onClick} type={type} disabled={isDisabled} {...openInNewTab()}>
			<p className='c-mediahub-cta__label'>{label || data.label}</p>
		</button>
	);
};

MediaHubCTA.propTypes = {
	data: PropTypes.object,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func,
}


export default MediaHubCTA;
