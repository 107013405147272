import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import UserContext from '../../context/UserContext';
import './AdditionalDataModal.scss'
import '../FormGroup/Form.scss'

const AdditionalDataModal = () => {
	const {register, formState:{errors}, handleSubmit} = useForm({mode:'onBlur'})

	const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false)
	const {user, updateProfile, isLoggedIn} = useContext(UserContext)

	useEffect(() => {
		const showModal = user && (!user.jobTitle || !user.company || !user.phoneNumber);
		setShowAdditionalDataModal(showModal);
	}, [user])

	const handleAdditionalDataSubmit = async (data) => {
		await updateProfile({...user, ...data})
		setShowAdditionalDataModal(false)
	}

	return (
		<Modal isOpen={isLoggedIn && showAdditionalDataModal} ariaHideApp={false} className='Modal'>
			<div className='additionalDataModal'>
				<div className='modalImage'/>
				<div className='modalTitleContainer'>
					<h1 className='modalTitle'>
						Before you continue
					</h1>
					<h1 className='modalTitle modalTitle__bold'> we need more information </h1>
				</div>
				<form onSubmit={handleSubmit(handleAdditionalDataSubmit)} className='additionalDataModal__formContainer w-full'>
					<div className='w-full'>
						<div className={`inputField inputField__modal flex flex-col required ${errors.phoneNumber ? 'error' : ''}`}>
							<label className='text-sm'>Phone Number</label>
							<input
								type="text"
								name={'phoneNumber'}
								autoComplete="off"
								{...register('phoneNumber',
									{pattern: {value: /^\+[1-9 ]{1}[0-9 ]{3,14}$/, message: 'Please enter a valid phone number'}, required: 'Please enter a phone number'})}
								className='inputField'
							/>
							<p className='text-red-500'>{errors.phoneNumber && errors.phoneNumber.message}</p>
						</div>
						<div className={`inputField inputField__modal flex flex-col required ${errors.company ? 'error' : ''}`}>
							<label className='text-sm'>Company</label>
							<input
								type="text"
								name={'company'}
								autoComplete="off"
								{...register('company', {required: 'Please enter a company name'})}
							/>
							<p className='text-red-500'>{errors.company && errors.company.message}</p>
						</div>
						<div className={`inputField inputField__modal flex flex-col required ${errors.jobTitle ? 'error' : ''}`}><label className='text-sm'>Job Title</label>
							<input
								type="text"
								name={'jobTitle'}
								autoComplete="off"
								{...register('jobTitle', {required: 'Please enter a job title'})}
							/>
							<p className='text-red-500'>{errors.jobTitle && errors.jobTitle.message}</p>
						</div>
						<button className={`c-mediahub-cta c-mediahub-cta__modal ${Object.keys(errors).length > 0 ? 'c-mediahub-cta--disabled' : ''}`} type='submit'><p className='c-mediahub-cta__label'>Continue</p></button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default AdditionalDataModal;
