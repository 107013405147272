import React from 'react';
import createIconComponent from './createIconComponent';

const IconArrowLGLeft = createIconComponent({
	content: (
		<g>
			<path d="M9.76,12l4.95,4.95-.71.71L9.05,12.71,8.34,12,14,6.34l.71.71Z"/>
		</g>
	),
	height: 24,
	width: 24
});

IconArrowLGLeft.displayName = 'IconArrowLGLeft';

export default IconArrowLGLeft;
