import { fetchPostData, fetchGetData } from '../../../utils/fetchApi';
import {fetchPageData} from "../../../utils/fetchApiData";

export const PhotoBoatSignup = async (values) => {

	const photoSignUpPayload = {dayofinterest: []};

	Object.keys(values).forEach(key => {
		switch (key) {
			case 'firstName':
				photoSignUpPayload.firstname = values[key];
				break;
			case 'lastName' :
				photoSignUpPayload.lastname = values[key];
				break;
			case 'phoneNumber' :
				photoSignUpPayload.phonenumber = values[key].replace(/\s/g, "");
				break;
			case 'company' :
				photoSignUpPayload.organization = values[key];
				break;
			case 'emailAddress' :
				photoSignUpPayload.emailAddress = values[key]
				break;
			default:
				photoSignUpPayload.dayofinterest.push({[key]: values[key]})
		}
	});

      const photoSignUpUrl = await fetchPageData("event/photoSignup");

      const photoSignUpResponseData = await fetchPostData(
        photoSignUpUrl,
        "POST",
        photoSignUpPayload,
        true
      );
      const photoSignUpResponseDataResp = await photoSignUpResponseData.json();
  return photoSignUpResponseDataResp;
}

export const PhotoBoatSignupFormInitialize = (setValue, contentfulData) => {
  const userUrl = fetchPageData("user");
		userUrl.then((response) => {
			const getUserUrl = response;
          	const allUserData = fetchGetData(getUserUrl, "GET", true);
			  allUserData
			  .then((response) => response.json())
			  .then((data) => {
				{contentfulData.formFields && contentfulData.formFields.map(({title, defaultValue}, index) => {
					if(title === "firstName")
						setValue(title, data.data.name["givenName"]);
					else if(title === "lastName")
						setValue(title, data.data.name["familyName"]);
					else if(title === "company") {
						let companyName = data.data[
							"urn:ietf:params:scim:schemas:idcs:extension:custom:User"
						  ] &&
						  data.data[
							"urn:ietf:params:scim:schemas:idcs:extension:custom:User"
						  ]["Organization"];
						  setValue(title, companyName);
					}
					else if(title === 'emailAddress') {
						setValue(title, data.data.emails[0].value)
					}
					else if(title === "phoneNumber") {
						let PhoneNumber = data.data.phoneNumbers &&
                        data.data["phoneNumbers"][0].value;
						setValue(title, PhoneNumber);
					}
          else if(title === "eventName") {
						setValue(title, defaultValue);
					}
				}
				)};
			});

		});
    return {}
}


