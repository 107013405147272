import React, {Component} from 'react';
import './RolexClock.scss';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import RolexWatch from '../RolexCountdown/RolexWatch';

const ONE_MILLISECOND = 1000;

const SelectedTime = {
	DEFAULT: 'default',
	ALTERNATE: 'alternate'
};

function convertToDate(dateTime) {
	return new Date(dateTime.year, dateTime.month, null, dateTime.hour, dateTime.minute, dateTime.second, dateTime.millisecond);
}

class RolexClock extends Component {
	state = this.getInitialState()


	getInitialState() {
		const time = this.props.timeZone !== null ? DateTime.local().setZone(this.props.timeZone) : null;
		const alternateTime = this.props.alternateTimezone !== null ? DateTime.local().setZone(this.props.alternateTimezone) : null;

		const selectedTime = SelectedTime.ALTERNATE;
		const watchTime = convertToDate(selectedTime === SelectedTime.DEFAULT ? time : alternateTime);

		return {
			time: time,
			alternateTime: alternateTime,
			watchTime: watchTime,
			selectedTime: selectedTime,
			lastSelectedTime: null,
		}
	}

	componentDidMount() {
		const millisBeforeNextSecond = (ONE_MILLISECOND - new Date().getMilliseconds());
		setTimeout(
			() => {
				this.tick();
				this.interval = setInterval(() => this.tick(), ONE_MILLISECOND);
			},
			millisBeforeNextSecond
		);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	tick() {
		this.setState(prevState => {
			const state = Object.assign(prevState);

			state.time = DateTime.local().setZone(this.props.timeZone)
			state.alternateTime = DateTime.local().setZone(this.props.alternateTimezone);

			return state;
		})
	}

	onSelectedTimeChanged = (selectedTime) => {
		if (this.state.selectedTime !== selectedTime) {
			this.setState(prevState => {
				const state = Object.assign(prevState);

				state.watchTime = convertToDate(selectedTime === SelectedTime.DEFAULT ? this.state.time : this.state.alternateTime);
				state.selectedTime = selectedTime;

				return state;
			})
		}
	};

	render() {
		return (
			<div className='banner'>
				<div className='logo-container'>
					<a target="_blank" rel="noopener noreferrer" aria-label='rolex clock'
					   href="https://www.rolex.com/?cmpid=dsp_Clock_sailgp.com_2019_rlx1900188">
						<img className="logo" src="/rolex/images/logo/white/logo_rolex.svg" alt='rolex clock'/>
					</a>
				</div>

				<div className='watch-container'>
					<RolexWatch time={this.state.watchTime}/>
				</div>

				<div className='time-option-container'>
					{this.props.timeZone !== null &&
					<div
						className={`time-option ${this.state.selectedTime === SelectedTime.DEFAULT ? 'time-option-selected' : ''}`}
						onClick={() => {
							this.onSelectedTimeChanged(SelectedTime.DEFAULT);
						}}
					>
						<p className="time-zone">{this.props.timeZoneLabel}</p>
						<p className="time">{this.state.time.toFormat(this.props.timeZoneFormat)}</p>
					</div>
					}

					{this.props.alternateTimezone !== null &&
					<div
						className={`time-option ${this.state.selectedTime === SelectedTime.ALTERNATE && this.props.timeZone !== null ? 'time-option-selected' : ''}`}
						onClick={() => {
							this.onSelectedTimeChanged(SelectedTime.ALTERNATE);
						}}
					>
						<p className="time-zone">{this.props.alternateTimezoneLabel}</p>
						<p className="time">{this.state.alternateTime.toFormat(this.props.alternateTimezoneFormat)}</p>
					</div>
					}
				</div>
			</div>
		);
	}
}


export default RolexClock;

RolexClock.propTypes = {
	timeZone: PropTypes
		.string,
	timeZoneLabel: PropTypes
		.string,
	timeZoneFormat: PropTypes
		.string,
	alternateTimezone: PropTypes
		.string,
	alternateTimezoneLabel: PropTypes
		.string,
	alternateTimezoneFormat: PropTypes
		.string

};
