import React from 'react';
import createIconComponent from './createIconComponent';

const IconArrowLGRight = createIconComponent({
	content: (
		<g>
			<path d="M14.24,12,9.29,7.05,10,6.34l4.95,4.95.71.71L10,17.66l-.71-.71Z"/>
		</g>
	),
	height: 24,
	width: 24
});

IconArrowLGRight.displayName = 'IconArrowLGRight';

export default IconArrowLGRight;
