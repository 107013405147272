import React, {Component} from 'react';
import '@styles-global';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import _ from 'lodash';
import {Cookies} from 'react-cookie';
import pageComponentsList from './../../../containers/ComponentsList';

import Logger from './../../../lib/logger';
import SideNavigationDrawerContainer from '../../NavContainer/SideNavigationDrawerContainer';
import MobileNavigationContainer from '../../NavContainer/MobileNavigationContainer';
import StickyHeaderContainer from '../../NavContainer/StickyHeaderContainer';
import CookieBannerContainer from './../../../components/CookieBanner/containers/CookieBannerContainer';
import NotificationArea from './../../../components/NotificationArea';
import OnwardJourney from './../../../components/OnwardJourney/OnwardJourneyBlock';
import {
	EditorialHeader,
	SponsorLogoList,
	MemoizedHeroBanner as HeroBanner,
	Footer,
	Button,
	ScreenLoadingOverlay
} from '@sailgp/sailgp-shared-components';
import {Grid} from '../../../components/Grid';
import RequireHigherPermissions from '../../../components/RequireHigherPermissions';
import AdditionalDataModal from '../../../components/AdditionalDataModal/AdditionalDataModal';
import {ssoLogin, verifyLogin} from '../../../utils/fetchApi';


const logger = Logger.get('AuthPageContainer');

class PageContainer extends Component {
	/**
	 * Get global components list based on the position they need to be rendered on.
	 * Some global components will have to come on top of the page ones some at the bottom.
	 *
	 * @param {String} position The position of the requested components. Can be `top` or `bottom`.
	 * @param {Object} pageData The page data object.
	 * @param {Object} cookieProvider Cookies provider object.
	 * @returns {Array} Components array for the requested position.
	 * @memberof PageContainer
	 */
	getGlobalPageComponents(position, pageData, cookieProvider) {
		const componentsList = [];
		const siteSettings = pageData.siteSettings;
		const hostUrl = pageData.hostUrl;
		const sectionNavigation = pageData.sectionNavigation || {};
		const content = pageData.content;
		const languages = pageData.languages;


		if (position === 'top') {
			if (_.get(pageData, 'serverSettings.contentOnly', false) === false) {
				componentsList.push({
					comp: StickyHeaderContainer,
					data: {...siteSettings, sectionNavigation, languages},
				});

				componentsList.push({
					comp: MobileNavigationContainer,
					data: {
						...siteSettings,
						sectionNavigation,
						languages,
					}
				});

				componentsList.push({
					comp: SideNavigationDrawerContainer,
					data: {
						...siteSettings,
						languages,
					}
				});

				componentsList.push({
					comp: CookieBannerContainer,
					data: {
						cookieMessage: siteSettings.cookieMessage,
						cookiePageUrl: siteSettings.cookiePageUrl,
						learnMoreLabel: siteSettings.learnMoreLabel,
						continueLabel: siteSettings.continueLabel,
						pageUrl: `${siteSettings.baseUrl}${content.url}`,
						rejectLabel: siteSettings.rejectLabel,
						saveAndCloseLabel: siteSettings.saveAndCloseLabel,
						cookieDescription: siteSettings.cookieDescription
					}
				});

				componentsList.push({
					comp: NotificationArea,
					data: {
						messages: siteSettings.alertMessages,
					}
				});
			}
			componentsList.push({
				comp: HeroBanner,
				data: {
					heading: content.heading,
					media: content.heroImage,
					showHero: content.showHero,
					isHomeHero: content.isHomeHero,
					darkBottomAngle: content.components.length === 0,
					subtitle: content.subTitle,
					theme: content.theme,
					title: content.heroIntro1,
					videoStreamUrl: content.videoStreamUrl,
					videoStreamText: content.videoStreamText,
					hideBroadcasterCta: content.hideBroadcasterCta,
					broadcasterConfigUrl: siteSettings.app_broadcasterConfigUrl,
					watchLiveLabel: content.watchLiveLabel,
				}
			});

			componentsList.push({
				comp: EditorialHeader,
				data: {
					pageInformation: content.pageInformation,
					relatedEntities: content.relatedEntities,
					date: content.publishDate,
					featureLabel: content.pageType,
					socialLinks: {
						facebook: siteSettings.facebookUrl,
						twitter: siteSettings.twitterHandle,
					},
					byLabel: content.byLabel,
					author: content.authorName,
					pageUrl: `${siteSettings.baseUrl}${content.url}`,
					heading: content.heading,
					hostnamePageUrl: `${hostUrl}${content.url}`
				}
			});

		} else if (position === 'bottom') {
			if (_.get(pageData, 'serverSettings.contentOnly', false) === false) {
				componentsList.push({
					comp: OnwardJourney,
					data: {
						pages: pageData.content && pageData.content.onwardJourney
					}
				});
				if (pageData && pageData.content && pageData.content.partners) {
					componentsList.push({
						comp: SponsorLogoList,
						data: {partners: pageData.content.partners, heading: pageData.content.partnersTitle}
					});
				}
				componentsList.push({
					comp: Footer,
					data: {
						copyrightNotice: siteSettings.copyrightNotice,
						footerNavigationLists: siteSettings.footerNavigationLists,
						footerUtilityLinks: siteSettings.footerUtilityLinks,
						socialLabel: siteSettings.socialLabel,
						logo: siteSettings.logo,
						socialLinks: {
							facebookUrl: siteSettings.facebookUrl,
							twitterHandle: siteSettings.twitterHandle,
							instagramUrl: siteSettings.instagramUrl,
							youTubeUrl: siteSettings.youTubeUrl,
							ticktokUrl: siteSettings.ticktokUrl
						},
						downloadAppLabel: siteSettings.downloadAppLabel,
						appStoreLink: siteSettings.appStoreLink,
						googlePlayLink: siteSettings.googlePlayLink,
						useDazzle: false,
						partners: siteSettings.partners
					}
				});
			}
		}

		const globalPageComponents = componentsList.map(({comp, data}) => {
			const SGPComponent = comp;

			return <SGPComponent
				key={nanoid()}
				data={data}
				cookieProvider={cookieProvider}
			/>
		});

		return globalPageComponents;
	}

	/**
	 * Get page components
	 * This will return an array of all components that need to rendered on top of each other.
	 * @param {Array} components Components needed for this page instance
	 * @returns {Array} pageComponents Array of all React components that matched the API request
	 * @memberof PageContainer
	 */
	getPageComponents(components) {
		const pageComponents = [];

		// Add here all components that can be rendered.
		// Each key needs to match the Contentful type for that component.
		const componentList = pageComponentsList.allPageComponents

		components.map((comp) => {
			if (componentList[comp.contentfulType]) {
				pageComponents.push({
					contentfulType: comp.contentfulType,
					contentfulId: comp.contentfulId,
					comp: componentList[comp.contentfulType],
				});
			} else {
				logger.error(`Trying to load component '${comp.contentfulType}' but it doesn't exist in components list!`);
			}

			return null;
		});

		return pageComponents;
	}


	render() {
		const {error, data, cookies} = this.props;

		if (error) return 'Something went wrong :(';

		if (data) {
			const {components, otherComponents} = data.content;
			const pageComponents = this.getPageComponents(components);
			const pageOtherComponents = this.getPageComponents(otherComponents);

			let componentsElements = pageComponents.map(({contentfulId, comp}) => {
				const SGPComponent = comp;

				return <SGPComponent
					key={nanoid()}
					data={_.find(components, ['contentfulId', contentfulId])}
					cookieProvider={cookies}
				/>
			});

			const wrapElements = (elements) => {
				return [<main role='main' key='main' id='main'>{elements.map((element) => {
					return element
				})}</main>]
			}

			const otherComponentsElements = pageOtherComponents.map(({contentfulId, comp}) => {
				const SGPComponent = comp;
				return <SGPComponent
					key={nanoid()}
					data={_.find(otherComponents, ['contentfulId', contentfulId])}
					cookieProvider={cookies}
				/>
			});


			componentsElements = componentsElements.concat(otherComponentsElements);
			// Wrap main tag around main page components
			componentsElements = wrapElements(componentsElements);

			// Add global components before and after the main page ones if required for logged in users
			if (this.props.isLoggedIn) {
				const callVerifyLogin = async () => {
					let isValidLogin = await verifyLogin();
					if(isValidLogin.status !== true){
						this.props.logOut();
					}
				}
				callVerifyLogin();
				componentsElements = componentsElements.concat(this.getGlobalPageComponents('bottom', data, cookies, this.props.isLoggedIn));
				componentsElements = this.getGlobalPageComponents('top', data, cookies, this.props.isLoggedIn).concat(componentsElements);
				
			}else{
				const getloginData = async () => {
					let ssoData = await ssoLogin();
					if(ssoData['status'] == "success"){
						this.props.ssoLogin(ssoData);
						this.props.history.push('/home');
					}else{
						// if sso login is not made or any error.
						// if user is not approved in mediahub error msg need to handle here
					}

				  };
				  getloginData()
			}

			const handleRenderView = () => {
				if (data.content.requiresLogin && !this.props.isLoggedIn) {
					if(typeof window !== 'undefined' && !sessionStorage.getItem("token")){
					return this.props.history.push('/user/login');
					}
				} else if (data.content.requiresLogin && this.props.isLoggedIn && (this.props.user.userPermissionLevel > data.content.userPermissionLevel)) {
					return componentsElements.map(element => {
						if (element.props.children) {
							return <element.type role={element.props.role} id={element.props.id} key={nanoid()}>
								<RequireHigherPermissions/>
							</element.type>
						} else return element;
					})
				} else {
					return componentsElements.map(element => {
						if (element.props.children) {
							return <element.type role={element.props.role} id={element.props.id} key={nanoid()}>
								{element.props.children}
							</element.type>
						} else return element;
					})
				}
			}


			return (
				<>
					{handleRenderView()}
					<AdditionalDataModal/>
				</>
			);
		}

		return null;
	}
}

PageContainer.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.object,
	data: PropTypes.object,
	cookies: PropTypes.instanceOf(Cookies).isRequired,
	context: PropTypes.object,
	isLoggedIn: PropTypes.bool
};

export default PageContainer;
