
//POST,PATCH API
import { fetchPageData } from '../utils/fetchApiData';
import {getSsoAPIURL} from "../lib/content-api-utils";

export const fetchPostData = async (url, method, body, loggedInStatus) => {
	try {
		const stringifiedBody = JSON.stringify(body)
		//loggedInStatus is type boolean where true means user loggedIn else not
		let headers = {};
		if (loggedInStatus) {
			headers = {
				'Content-Type': 'application/json',
				role: 2,
			};
		} else {
			headers = {
				'Content-Type': 'application/json',
			};
		}
		const responseData = await fetch(url, {
			method: method,
			credentials: 'include',
			headers: headers,
			body: stringifiedBody,
		});
		return responseData;
	} catch (error) {
		console.log(error) ;
		return error
	}

};

//GET,DELETE API
export const fetchGetData = async (url, method, loggedInStatus, permanentRole, redirectURL) => {
	const headers = {'Content-Type': 'application/json'};

	if (loggedInStatus) {
		headers['Cache-Control'] = 'no-cache, max-age=0';
		headers.role = permanentRole === 'Journalist' ? 3 : 2;
	}
	if (redirectURL) {
		headers.redirecturl = redirectURL
	}
	const responseData = await fetch(url, {
		method: method,
		credentials: 'include',
		headers: headers,
	});
	return responseData;
};

//Only For User API
export const fetchGetUserData = async (url) => {
	let headers = {
		'Content-Type': 'application/json',
	};
	let urlParam = url + "?appName="+process.env.APP_NAME_MEDIAHUB;
	const responseData = await fetch(urlParam, {
		method: "GET",
		credentials: 'include',
		headers: headers
	});
	return responseData;
};

//Only for Upload Image
export const fetchPostUploadImage = async (
	url,
	method,
	body,
	loggedInStatus
) => {
	let headers = {};
	if (loggedInStatus) {
		headers = {
			role: 2,
		};
	}
	const responseData = await fetch(url, {
		method: method,
		credentials: 'include',
		headers: headers,
		body: body,
	});
	return responseData;
};

//Only for Add and Update Events
export const fetchPostEditEvent = async (
	url,
	method,
	body,
	loggedInStatus
) => {
	let headers = {};
	if (loggedInStatus) {
		headers = {
			role: 2,
		};
	}
	const responseData = await fetch(url, {
		method: method,
		credentials: 'include',
		headers: headers,
		body: body,
	});
	return responseData;
};

//Only for logout
export const userLogout = async () => {

	const logoutURL =  await getSsoAPIURL(`user/logout`);
	const responseData = await fetch(logoutURL, {
		method: "POST",
		credentials: 'include',
	});
	return responseData;
};

export async function ssoLogin() {
	const origin = typeof window !== 'undefined' && window.location.origin;
	const stringifiedBody = JSON.stringify({landingUrl : origin, applicationName : process.env.APP_NAME_MEDIAHUB});
	const submitUrl = await getSsoAPIURL(`user/sso-login`);
	try {
		const fetchResponse = await fetch(`${submitUrl}` , {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			body: stringifiedBody,
		});
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}

export async function verifyLogin() {

	let submitUrl = await getSsoAPIURL(`user/verify`);
	try {
		const fetchResponse = await fetch(`${submitUrl}` , {
			method: "GET",
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}