const reParseTimeZoneOffset = (timeZoneString) => {
	const sign = timeZoneString[3] === '-' ? -1 : 1;
	const hours = parseInt(timeZoneString.slice(4, 6), 10);
	const minutes = parseInt(timeZoneString.slice(7), 10);
	return sign * (hours * 60 + minutes);
};

export const reFormatEventDate = (eventDate, eventTimeZone) => {
	const date = new Date(eventDate);
	const timeZoneOffset = reParseTimeZoneOffset(eventTimeZone);
	date.setMinutes(date.getMinutes() + timeZoneOffset);
	return date;
};
