import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconFilterPhotos = createIconComponent({
	content: (
		<Fragment>
			<g>
				<path d="M23,9V4H4v19h5v5h19V9H23z M27,24.3l-3-3l-3,3l-5-6l-6,7V10h17V24.3z M5,22V5h17v4H9v13H5z M10,27v-0.2l6-7l5,6l3-3l3,3V27H10z"/>
				<path d="M21,17.5c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5S19.6,17.5,21,17.5z M21,13.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5S20.2,13.5,21,13.5z"/>
			</g>
		</Fragment>
	),
	height: 32,
	width: 32
});

IconFilterPhotos.displayName = 'IconFilterPhotos';

export default IconFilterPhotos;
