import { Col, Table } from "antd";
import React from "react";

export default function TableComponent(props) {
  const {columns, tableDataSource, tableFirstPosition, tableSecondPosition, pageSize, itemRender, tableLayout} = props;
  return (
    <Col xl={24} lg={24} md={24} sm={24} xs={24} className="table-wrap">
      <Table
        tableLayout={tableLayout}
        columns={columns}
        pagination={{
          showSizeChanger: true,
          position: [tableFirstPosition, tableSecondPosition],
          itemRender: itemRender,
          pageSizeOptions: pageSize,
        }}
        dataSource={tableDataSource}
      />
    </Col>
  );
}
