/* eslint "react/display-name": 0 */

import { createElement } from 'react';
import PropTypes from 'prop-types';

const createIconComponent = ({ content, height, width }) => (initialProps) => {
	const computedSize = initialProps.size || '1em';
	const className = initialProps.className ? initialProps.className : '';
	const {attr, ...svgProps} = initialProps;
	const h = height ? height : 24;
	const w = width ? width : 24;

	const elProps = {
		stroke: 'currentColor',
		fill: 'currentColor',
		strokeWidth: '0',
		...attr,
		...svgProps,
		className: className,
		style: {
			color: initialProps.color,
			...initialProps.style
		},
		height: computedSize,
		width: computedSize,
		viewBox: `0 0 ${w} ${h}`
	}

	return createElement('svg', elProps, content);
}

createIconComponent.propTypes = {
	size: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	style: PropTypes.object,
	content: PropTypes.string,
	attr: PropTypes.object
};

export default createIconComponent;
