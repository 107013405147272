import React from 'react';
import createIconComponent from './createIconComponent';

const Warning = createIconComponent({
	content: (
		<g>
			<circle cx="10.452" cy="10.452" r="10.452" transform="translate(.548 .548)"/>
			<path d="M10.338 4.92l-.009 6.369.384 1.92h.576l.372-1.822V4.92h-1.323zm.001 10.838v1.323h1.322v-1.323H10.34z"/>
		</g>
	),
	height: 40,
	width: 40
});

Warning.displayName = 'Warning';

export default Warning;
