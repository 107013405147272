import React, { memo, Component } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import { Grid, Row, Cell } from './../Grid';
import 'video.js/dist/video-js.css';
import Analytics from '../../lib/analytics';
import './BroadcastVideo.scss';
import BroadcastInfo from './BroadcasterInfo';
import Logger from '../../lib/logger';

const logger = Logger.get('BroadcastVideo');

class BroadcastVideo extends Component {
	constructor(props) {
		super(props);

		this.setRef = this.setRef.bind(this);
		this.updateVisibility = this.updateVisibility.bind(this);
		this.trackPlayEvent = this.trackPlayEvent.bind(this);
		this.state = {
			broadcastInfo: undefined,
			videoUrl: undefined,
		};
		this.videoJsOptions = {
			controls: true,
			responsive: true,
			liveui: true,
			aspectRatio: '16:9',
			sources: [],
			controlBar: {
				audioTrackButton: false,
				fullscreenToggle: true,
				playProgressBar: false,
				progressControl: false,
			}
		};	
	}

	componentDidMount() {
		const { videoStreamUrl } = this.props;

		if (!videoStreamUrl) return;
		fetch(videoStreamUrl)
			.then(e => {
				if (e.status >= 200 && e.status <= 300) {
					this.setState({
						videoUrl: videoStreamUrl,
					}, () => {
						this.videoJsOptions.sources.push({
							src: this.state.videoUrl,
							type: 'application/x-mpegURL',
						});
						this.player = videojs(this.videoNode, this.videoJsOptions);
						this.player.on('play', () => this.trackPlayEvent());
					});
				}
				return e;
			})
			.catch(error => logger.error(error));
	}

	componentWillUnmount() {
		if (this.player) {
			this.player.dispose();
		}
	}

	trackPlayEvent() {
		const { heading } = this.props;

		Analytics.event({
			'eventName': 'Video-Engagement',
			'category': 'Video Engagement',
			'action': heading,
			'label': 'Broadcast Video'
		});
	}

	updateVisibility(broadcastInfo) {
		this.setState({
			broadcastInfo,
		}, () => {
			// if there is no live video, reset videoUrl state
			if (this.state.broadcastInfo.noLiveVideo) {
				this.setState({
					videoUrl: undefined,
				});
			}
		});
	}

	setRef(ref, element) {
		this[ref] = element;
	}

	// wrap the player in a div with a `data-vjs-player` attribute
	// so videojs won't create additional wrapper in the DOM
	// see https://github.com/videojs/video.js/pull/3856
	render() {
		const { broadcastInfo, videoUrl } = this.state;
		const { heroVariant, hideBroadcasterCta, videoStreamText, configUrl, watchLiveLabel } = this.props;

		// TODO: we still need to integrate the above props to the contentful editorial component,
		// 			 currently these are only being used via the hero banner

		return (
			<Grid>
				<Row>
					<Cell mdCols={6} lgCols={1} xlCols={1}></Cell>
					<Cell mdCols={6} lgCols={10} xlCols={10}>
						<div className={`c-broadcast-video${heroVariant ? ' c-broadcast-video--hero' : ''}`}>
							<BroadcastInfo
								copy={videoStreamText}
								info={broadcastInfo} 
								updateVisibility={this.updateVisibility}
								videoAvailable={videoUrl} 
								hideBroadcasterCta={hideBroadcasterCta} 
								configUrl={configUrl}
								watchLiveLabel={watchLiveLabel}
							/>
							{
								videoUrl &&
									<div data-vjs-player>
										<video ref={e => this.setRef('videoNode', e)} className="video-js vjs-default-skin vjs-big-play-centered"></video>
									</div>
							}
						</div>
					</Cell>
				</Row>
			</Grid>
		)
	}
}

BroadcastVideo.propTypes = {
	heroVariant: PropTypes.bool,
	hideBroadcasterCta: PropTypes.bool,
	videoStreamText: PropTypes.string,
	videoStreamUrl: PropTypes.string,
	heading: PropTypes.string,
	configUrl: PropTypes.string,
};

export default memo(BroadcastVideo);
