import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import PageContext from '../../context/PageContext';
import {RaceCalendarContainer} from '@sailgp/sailgp-shared-components';

export class RaceCalenderContainer extends PureComponent {
	render() {
		return (
			<PageContext.Consumer>
				{({activeLanguage}) => (
					<RaceCalendarContainer
						props={this.props}
						data={this.props.data}
						activeLanguage={activeLanguage}
						isMediaHub={true}
					/>
				)}
			</PageContext.Consumer>
		)
	}
}

RaceCalenderContainer.propTypes = {
	data: PropTypes.object,
	isParentTab: PropTypes.bool,
};
