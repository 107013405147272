import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconSlash = createIconComponent({
	content: (
		<Fragment>
			<path d="M8.533 0l-8.533 32h1.067l8.533-31.999z"></path>
		</Fragment>
	),
	height: 32,
	width: 32
});

IconSlash.displayName = 'IconSlash';

export default IconSlash;
