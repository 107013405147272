import React from 'react';

const PageContext = React.createContext({
	isSectionNavPanelOpen: false,
	isSideDrawerOpen: false,
	isLanguageSelectOpen: false,
	activeLanguage: undefined,
	closeLanguageSelect:() => {},
	toggleSectionNavPanelOpen:() => {},
	toggleSideDrawerOpen:() => {},
	toggleLanguageSelectOpen:() => {},
	closeSecondaryNav: () => {},
	isSecondaryNavOpen: false,
	lastClick: ''
});

export default PageContext;
